:global(.main){ width:1200px; margin: 0 auto;}
:global(.bgff){ background: #ffffff;}
.banner{vertical-align:top; height: 340px; background: url(../../assets/images/banner-course.png) no-repeat center top;}
.banner img{ width: 100%;}
.course-bg{ background: #F0F0F0; overflow: hidden;}
.classify{ width: 1200px; margin:0 auto; color: #ffffff; padding-top:2px; overflow: hidden;}
.classify .left{ width:400px; float: left; position: relative;}
.classify .right{ width: 798px; float: right; padding-left:2px;}
.carte{ width: 198px; float: left;}
.carte .grid{ width: 198px; height: 80px; font-size: 18px; text-align: center; background: #1F1F1F; line-height: 25px; margin-bottom:4px;}
.carte .grid:hover{ background: #F0B645; color: #000000;}
.carte .grid.on{ background: #F0B645; color: #000000;}
.grid p{ padding-top:15px; height: 25px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap;}
.grid span{ display: block; height: 25px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap;}
.carte-l{ float: left;}
.carte-r{ margin-left:4px; position: absolute; top:0; left:196px; z-index: 5;}
.wid400{ width: 400px;}
.carte-r .grid{ background: #999999; margin-left:2px;}
.wid400 .grid:nth-of-type(1){ float: left;}
.wid400 .grid:nth-of-type(2){ float: left;}
.wid400 .grid:nth-of-type(3){ float: left;}
.wid400 .grid:nth-of-type(4){ float: left;}
.wid400 .grid:nth-of-type(5){ float: left;}
.wid400 .grid:nth-of-type(6){ float: left;}
.wid400 .grid:nth-of-type(7){ float: left;}
.wid400 .grid:nth-of-type(8){ float: left;}
.wid400 .grid:nth-of-type(9){ float: left;}
.wid400 .grid:nth-of-type(10){ float: left;}
.wid400 .grid:nth-of-type(11){ float: left;}
.wid400 .grid:nth-of-type(12){ float: left;}
.wid400 .grid:nth-of-type(13){ float: left;}
.wid400 .grid:nth-of-type(14){ float: left;}
.wid400 .grid:nth-of-type(15){ float: left;}
.wid400 .grid:nth-of-type(16){ float: left;}
.wid400 .grid:nth-of-type(17){ clear: both;}
.course-show{ margin-left:-3px;}
.course-one{ width:397px; height: 257px; background: #1F1F1F; padding-top:75px; position:relative; float: left; margin:0 0 5px 3px;}
.course-one .name{ height: 92px; line-height: 92px; color: #F0B645; font-size: 20px; background:#282828; padding-left: 27px;}
.course-one img{ position:absolute; bottom:165px; right:68px;}
.course-one p{ font-size: 16px; padding:30px 30px 0; line-height: 25px;}
.course-bg2{ background: #3b3b3b; overflow: hidden;}
.course-set{ width:1200px; margin: 0 auto; padding:100px 0 70px;}
.course-title{ background:url(../../assets/images/xx.png) no-repeat; height: 66px; line-height: 66px; font-size: 30px; color: #ffffff; margin-bottom: 60px;}
.course-title .bg{ padding-left:60px;}
.course-table{ font-size: 22px; color: #ffffff;}
.course-table td{ background: #1f1f1f; height: 83px; text-align: center;}
.activity-bg{ background: url(../../assets/images/activity-bg.png) no-repeat; background-size: cover; padding-bottom: 130px; overflow: hidden;}
.activity{ padding-top:100px; width:1200px; margin:0 auto;}
.activity-list{ margin-left:-19px;}
.activity-list .a-one{ width:590px; height: 373px; float: left; margin-left:19px; border-radius: 10px; box-shadow:0 0 20px 0px #1b1b1b;}

/* 改版 */
.superiority{ height: 228px; background: url(../../assets/images/superiority-bg.png) no-repeat; background-size: cover; box-sizing: border-box; padding-top:50px;}
.superiority .item{width: 240px; text-align: center; color: #ffffff; float: left;}
.superiority .item .icon{ display: block;width: 72px; height: 69px;margin:0 auto; vertical-align: top;}
.superiority .item .icon1{ background:url(../../assets/images/s-icon1.png) no-repeat; background-size: cover;}
.superiority .item .icon2{ background:url(../../assets/images/s-icon2.png) no-repeat; background-size: cover;}
.superiority .item .icon3{ background:url(../../assets/images/s-icon3.png) no-repeat; background-size: cover;}
.superiority .item .icon4{ background:url(../../assets/images/s-icon4.png) no-repeat; background-size: cover;}
.superiority .item .icon5{ background:url(../../assets/images/s-icon5.png) no-repeat; background-size: cover;}
.superiority .item .line{ display: inline-block; width: 35px; height: 5px; background: #ffffff;vertical-align: top; margin:18px 0;}
.superiority .item p{ font-size: 18px; vertical-align: top;}
.superiority .item:hover{ color: #F0B645;}
.superiority .item:hover .line{ background: #F0B645;}
.superiority .item:hover .icon1{ background:url(../../assets/images/s-icon1-on.png) no-repeat; background-size: cover;}
.superiority .item:hover .icon2{ background:url(../../assets/images/s-icon2-on.png) no-repeat; background-size: cover;}
.superiority .item:hover .icon3{ background:url(../../assets/images/s-icon3-on.png) no-repeat; background-size: cover;}
.superiority .item:hover .icon4{ background:url(../../assets/images/s-icon4-on.png) no-repeat; background-size: cover;}
.superiority .item:hover .icon5{ background:url(../../assets/images/s-icon5-on.png) no-repeat; background-size: cover;}

.recom{overflow: hidden; width: 1200px; padding-bottom: 35px;}
.recom .row{ margin-left: -39px; border-bottom: 1px solid #e0e0e0; overflow: hidden;}
.recom .row:hover{border-bottom: 1px solid #F0B645;}
.recom .row:nth-last-of-type(1){ padding-top: 35px;}
.recom .item{ width: 270px; color: #ffffff; float: left; line-height: 30px; margin-left:39px;}
.recom .item .pic{ height: 128px; position: relative; overflow: hidden;}
.recom .pic img{ width: 100%; height: 100%;}
.recom .pic .mark{ font-size: 18px; position: absolute; left:0; top:0; width: 100%; height: 100%; text-align: center; display: flex; flex-direction: column; justify-content: center;}
.recom .pic .mark span{ font-size: 16px;}
.recom .pic .btn{ position: absolute; left: 0; bottom: -50px; width:100%; height: 50px; line-height: 50px; font-size: 18px; background: #F0B645; text-align: center; color: #333333; font-weight: bold; transition: .3s;}
.recom .item  .line{ width: 70px; height: 6px; margin:30px auto 0; background: #F0F0F0;}
.recom .item:hover .mark{ display: none;}
.recom .item:hover .btn{ bottom: 0px;}
.recom .item:hover .line{ background: #F0B645; border-radius: 3px;}

.ouractivity{ background: url(../../assets/images/activities-bg.png) no-repeat; background-size: cover; overflow: hidden; padding-bottom: 50px;}
.ouractivity .list{ margin-left: -39px;}
.ouractivity .item{ margin-left: 39px; width: 560px; float: left;}
.ouractivity .item .btn{ width: 560px; height: 84px; box-sizing: border-box; border:1px solid #F0B645; line-height: 82px; text-align: center; font-size: 30px; color: #333333; margin-top: 24px;}
.ouractivity .item:hover .btn{ background: #F0B645; font-weight: bold;}