.wrapbg{background:#F0F0F0}
:global(.wrap){ width:1200px; margin: 0 auto;}
.wrap{ width:1200px; margin: 0 auto;}
a{color:#fff}
:global(.button1){width:150px;height:45px;line-height:45px;text-align:center;font-size:16px;color:#333;background:#ecab2f;border:2px solid #ecab2f;display:inline-block;border-radius:23px}
:global(.button2){width:150px;height:45px;line-height:45px;text-align:center;font-size:16px;color:#fff;background:0 0;border:2px solid #ecab2f;display:inline-block;border-radius:23px}

:global(.banner){width:100%;position:relative}
:global(.banner div){ outline: none; cursor:pointer; vertical-align: top;}
.indexbanner1{ height: 478px; background: url(../../assets/images/banner-index1.png) center top;}
.indexbanner2{ height: 478px; background: url(../../assets/images/banner-index2.png) center top;}
.indexbanner3{ height: 478px; background: url(../../assets/images/banner-index3.png) center top;}
.banner .pic1{display:block; width: 100%;}
.banner .pic1 img{width:100%}
.banner .num{position:absolute;bottom:50px;left:0;width:100%;height:20px;text-align:center}
.banner .num a{display:inline-block;width:20px;height:20px;background:url(../../assets/images/icon.png) no-repeat;background-position:-46px 0;margin:0 15px}
.banner .num .on{background-position:-71px -48px}
.achieve{background:#fff}
.achieve .wrap{ overflow: hidden;}
.achieve ul{padding:30px 0;overflow:hidden;margin:0 -60px}
.achieve li{width:219px;height:90px;float:left;text-align:center;font-size:12px;color:#333;border-right:1px solid #edcd86;font-weight:700;padding-top:20px}
.achieve li span{font-size:31px;color:#333;display:inline-block;height:32px;line-height:32px;padding-bottom:15px}
.achieve .li2 span{color:#ecab2f}
.achieve .li4 span{color:#ecab2f}
.achieve .li6 span{color:#ecab2f}
.choose{background:url(../../assets/images/choose-bg.png) no-repeat center top;padding-bottom:52px;overflow:hidden}
.title{padding:50px 0;position:relative}
.title.center{ text-align: center;}
.title p{font-size:20px;line-height:20px;color:#fff;padding:20px 0 50px}
.title :global(.button1){position:absolute;right:0;top:52px}
.title :global(.button2){position:absolute;right:0;top:52px}
.superior{margin-left:-23px}
.superior li{width:221px;height:196px;background:#fff;float:left;text-align:center;font-size:16px;color:#333;overflow:hidden;position:relative;margin-left:23px}
.superior li .name{font-weight:700}
.superior li img{display:inline-block;width:80px;height:76px;margin-top:30px}
.superior li .line{display:block;width:38px;height:4px;background:#eda925;margin:20px auto 16px}
.superior li:hover .layer{display:block;top:0}
.superior .layer{position:absolute;left:0;top:196px;width:181px;padding:20px 20px 0;height:196px;background:#f0b645;font-size:12px;transition:.5s}
.superior .layer .name{font-size:16px}
.superior .layer .b-line{display:block;width:38px;height:4px;background:#333;margin:12px auto 12px}
.superior .layer p{text-align:left;font-size:12px}
.top-level{width:100%;position:relative}
.top-level img{width:100%;height:260px}
.top-level .info{width:100%;height:70px; overflow:hidden;background:rgba(255,255,255,.5);position:absolute;left:0;bottom:0;font-size:14px;color:#333; transition:.8s;}
.top-level .info p{height:25px;line-height:25px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}
.top-level .info .name{padding:0 10px 0 5%;font-size:16px;margin-top:5px;}
.top-level .info .name p{padding-bottom:5px}
.top-level .info .name .eng{font-size:18px;font-weight:700}
.top-level .info .origin{padding-left:10px;}
.top-level .info .btn{float:right;width:20%;height:50px;border:1px solid #ecab2f;line-height:50px;text-align:center;font-size:16px;color:#333;margin:29px 5% 0 0}
.top-level .label{position:absolute;left:57%;top:-20px;margin-left:-13%;width:163px;height:35px;background:url(../../assets/images/label.jpg) no-repeat; background-size: contain; z-index: 10;}
.top-level li:hover .info{ height: 130px;}
.padB5{padding-bottom:5px}
.teacher-list{padding:0px 0 50px;position:relative;overflow:hidden; width: 100%}
.teacher-list .left{position:absolute;left:30px;top:50%;margin-top:-83px;width:66px;height:125px;background:url(../../assets/images/arrow-l.png) no-repeat}
.teacher-list .right{position:absolute;right:0;top:50%;margin-top:-83px;width:66px;height:125px;background:url(../../assets/images/arrow-r.png) no-repeat}
.teacher-list .num{height:20px;padding:55px 0 25px;overflow:hidden;clear:both;text-align:center}
.teacher-list .num span{display:inline-block;width:20px;height:20px;background:url(../../assets/images/icon.png) no-repeat;background-position:0 -180px;margin:0 15px}
.teacher-list .num .on{background-position:-21px -180px}
.teacher-list .pic{width:100%;height:185px;position:relative}
.teacher-list .pic .sc{display:block;width:100%;height:185px;position:absolute;left:0;top:0;display:none;background:rgba(0,0,0,.85);text-align:center;cursor: pointer;}
.teacher-list .pic .sc .c{width:89px;height:89px; background: url(../../assets/images/circle.png) no-repeat; display:inline-block;margin-top:40px; border:none; padding:0; border-radius: 0; font-size: 12px; color: #f0b645; line-height: 89px;}
.teacher-list .pic:hover .sc{display:block; border:none; padding:0; border-radius: 0;}
.teacher-list .pic:hover .sc::after{ border:none; padding:0; border-radius: 0;}
.teacher-list img{width:100%;height:185px}
.teacher-list .info{background:#ffffff;font-size:14px;color:#333;text-align:center;line-height:23px;padding:5px 0 5px}
.teacher-list .info p{height:23px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap; font-weight: 700;}
.teacher-list .info .col666{ color: #666666; font-weight: normal;}
.adv{width:100%; background: url(../../assets/images/adv2.jpg) no-repeat center top; height: 190px; cursor: pointer;}
.adv img{width:100%; cursor:pointer}
.offer{ background: #2E2E2E;}

.course .super{background:#fff;height:143px;padding:0 60px 0 70px; margin-top:50px;font-size:14px;border:1px solid #e6e6e6}
.course .super .name{color:#333;float:left;padding-top:25px}
.course .super .name strong{font-size:24px;display:block;height:30px;line-height:30px}
.course .super .name p{font-size:16px;padding:10px 0 10px}
.course .super .name span{color:#666;display:block;height:25px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}
.course .super .head{float:left;width:242px;height:143px;background:#ecab2f;-webkit-transform:skew(15deg);transform:skew(15deg);margin-left:30px;text-align:center}
.course .super .head img{width:116px;height:116px;border-radius:50%;-webkit-transform:skew(-15deg);transform:skew(-15deg);margin-top:15px}
.course .super .info{float:left;margin-left:85px;width:620px;color:#333;padding-top:20px;line-height:25px}
.plan-list{margin-left:-23px;padding-top:20px;overflow:hidden}
.plan-list li{width:383px;height:258px;border:1px solid #fff;float:left;margin-left:22px;font-size:16px;color:#333;background:#fff;margin-top:30px;position:relative}
.plan-list li .name{font-size:20px;line-height:34px;padding-left:45px;margin-top:43px}
.plan-list li .line{display:inline-block;width:7px;height:34px;background:#ff3a00;vertical-align:top;margin-right:22px}
.plan-list li .info{padding-left:45px;line-height:30px;margin-top:80px}
.plan-list li img{position:absolute;right:0;top:0}
.plan-list li .show-pic{transition:.5s}
.plan-list li:hover .show-pic{-webkit-transform:scale(1);transform:scale(1);transition:.5s}
.plan-list li:hover .name{color:#ff3a00}
.plan-list li:hover .line{background:#ff3a00}
.plan-list .plan1{width:586px}
.plan-list .plan1 .show-pic{position:absolute;right:0;top:0;width:204px;height:258px;background:url(../../assets/images/plan1.png) no-repeat right center;-webkit-transform:scale(0.8);transform:scale(0.8);}
.plan-list .plan2{width:586px}
.plan-list .plan2 .line{background:#001eff}
.plan-list .plan2 .show-pic{position:absolute;right:20px;top:0;width:297px;height:258px;background:url(../../assets/images/plan2.png) no-repeat right center;-webkit-transform:scale(0.8);transform:scale(0.8);}
.plan-list .plan3 .line{background:#f59e21}
.plan-list .plan3 .show-pic{position:absolute;right:10px;top:0;width:180px;height:258px;background:url(../../assets/images/plan3.png) no-repeat right center;-webkit-transform:scale(0.8);transform:scale(0.8);}
.plan-list .plan4 .line{background:#4dccfb}
.plan-list .plan4 .tit{display:inline-block}
.plan-list .plan4 .show-pic{position:absolute;right:10px;top:0;width:257px;height:258px;background:url(../../assets/images/plan4.png) no-repeat right center;-webkit-transform:scale(0.8);transform:scale(0.8);}
.plan-list .plan4 .info{margin-top:50px}
.plan-list .plan5 .line{background:#f521e4}
.plan-list .plan5 .show-pic{position:absolute;right:-20px;top:0;width:197px;height:258px;background:url(../../assets/images/plan5.png) no-repeat right center; background-size:contain; -webkit-transform:scale(0.8); transform:scale(0.8);}

/* 精选产品 */
:global(.products-list){ overflow: hidden; margin-left: -25px; padding-bottom: 20px;}
:global(.products-list li){ float: left; margin-left: 25px; height: 184px; position: relative;overflow: hidden; margin-bottom: 30px;}
:global(.products-list li .info){ background:rgba(0 , 0, 0, .5); font-size: 14px; color: #ffffff; position: absolute; left:0; top:184px; height: 184px; box-sizing: border-box; line-height: 23px; transition: .5s;}
:global(.products-list li .tit){  height: 42px; position: absolute; left: 0; z-index: 10; }
:global(.products-list .long){ width: 586px;}
:global(.products-list .long img){ width: 100%; height: 100%;}
:global(.products-list .long .tit){ width: 318px; top: 30px;}
:global(.products-list .long .info){ width: 397px; padding:85px 34px 0 80px;}
:global(.products-list .short){ width: 385px; margin-left: 23px;}
:global(.products-list .short img){ width: 100%; height: 100%;}
:global(.products-list .short .tit){ top:12px;}
:global(.products-list .short .info){ width: 385px; padding:85px 34px 0 34px;}
:global(.products-list li:hover .info){ top:0;}
:global(.products-list li .tit1){ background: url(../../assets/images/pr-tit1.png) no-repeat;}
:global(.products-list li .tit2){ background: url(../../assets/images/pr-tit2.png) no-repeat;}
:global(.products-list li .tit3){ width: 234px; background: url(../../assets/images/pr-tit3.png) no-repeat;}
:global(.products-list li .tit4){ width: 294px; background: url(../../assets/images/pr-tit4.png) no-repeat;}
:global(.products-list li .tit5){ width: 262px; background: url(../../assets/images/pr-tit5.png) no-repeat;}

/* 校园库 */
.colleges{background:url(../../assets/images/b.jpg) no-repeat center bottom #2a2a2a}
.colleges-list{margin:-25px 0 0 -20px;padding-bottom:93px}
.colleges-list::after{ clear: both; content: "."; height: 0; visibility: hidden; display: block;}
.colleges-list li{width:132px;height:132px;margin:25px 0 0 20px;float:left; position: relative; text-align: center;}
.colleges-list li img{width:132px;height:132px;}
.colleges-list li .name{position: absolute; width: 150px; height: 150px; left: -9px; top:-9px; background:rgba(0, 0, 0, .9); color: #ECAB2F; font-size: 16px; display: flex; flex-direction: column; justify-content: center; align-items: center; -webkit-transform:scale(0); transform:scale(0);  transition: .5s; border:1px solid #ECAB2F;}
.colleges-list li strong{display: block; padding:0 10px 20px;}
.colleges-list li span{ display: inline-block; height: 2px; width: 30px; background:#ECAB2F; }
.colleges-list li:hover .name{ -webkit-transform: scale(1); transform: scale(1);}

/* 最新资讯 */
.latest{overflow:hidden; background: #ffffff;}
.latest .left{width:655px;float:left}
.latest .right{width:504px;float:right;overflow: hidden; position: relative;}
.latest .right .list{ width:504px;}
.latest .right .name{font-size:20px;color:#fff;height:22px;line-height:22px;display:inline-block;overflow:hidden;width:100%;padding-bottom:10px}
.latest .right .name span{float:left}
.latest .right .name .num{height:20px;float:right;text-align:right}
.latest .right .name .num b{display:inline-block;width:20px;height:20px;background:url(../../assets/images/icon.png) no-repeat;background-position:0 -180px;margin-left:25px}
.latest .right .name .num .on{background-position:-21px -180px}
.latest .video{width:655px;height:408px; position: relative;}
.latest .video img{width:100%;height:100%}
.latest .video .text{ height:40px;padding:5px 0 0 20px;line-height:40px;position:absolute;left:10px; right:10px; bottom:10px;font-size:12px;background:rgba(255,255,255,.3);color:#fff}
.latest .news{margin-left:-20px; overflow:hidden}
.latest .news-item{width:385px;height:258px;float:left;position:relative;margin-left:20px; cursor: pointer;}
.latest .news-item img{width:100%;height:100%}
.latest .news-item .text{width:385px;height:60px;padding:0px 0 0 20px;line-height:60px; box-sizing:border-box;position:absolute;left:0px;bottom:0px;font-size:16px;background:rgba(0,0,0,.8);color:#fff}
.latest .news-item .text p{ height: 60px; line-height: 60px; overflow: hidden; width: 266px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap}
.latest .list{font-size:14px;color:#c5c5c5}
.latest .list li{border-top:1px solid #999;margin-top:20px;background:url(../../assets/images/li.png) no-repeat left center;height:52px;line-height:26px;padding:20px 0 20px 60px}
.latest .list li p{font-size:16px;color:#fff}
.latest .news-item i{ position: absolute; right: 15px; bottom: 15px; width: 33px; height: 33px; background:url(../../assets/images/play-btn2.png) no-repeat;}

/* 最新资讯新设计 */
.morevideo{ font-size: 16px; color: #333333; background: url(../../assets/images/gengduo.png) no-repeat right center; padding-right: 25px; line-height: 21px; position: absolute; right: 0; bottom: 30px;}
.morenews{ height: 21px; line-height: 21px; position: relative; margin:30px 0;}
.morenews .line{ height: 1px; background: #F0F0F0; position: absolute; left:0; top:50%; margin-top:.5px; width: 100%;}
.morenews .more{ font-size: 16px; color: #333333; background: url(../../assets/images/gengduo.png) no-repeat right center #ffffff; padding:0 25px; line-height: 21px; position: absolute; right: 0; bottom: 0px;}
.newslist{ padding-bottom: 30px; margin-left: -30px;}
.newslist .item{width: 590px; height: 108px; box-sizing: border-box; padding:6px 6px 6px 0; float: left; margin:0 0 20px 25px;}
.newslist .item:hover{background:#F0F0F0;}
.newslist .item .pic{ width: 142px; height: 96px; float: left;}
.newslist .item .pic img{ width: 100%; height: 100%;}
.newslist .item .text{ font-size: 14px; color: #333333; margin-left: 165px;}
.newslist .item .text .tit{ line-height: 22px; height: 44px;}
.newslist .item .text .tit a{ color: #333333;}
.newslist .item .text .yuan{ font-size: 12px; color: #666666;margin-top: 5px;}
.newslist .item .text .yuan span{ display: block; width: 66px; height: 20px; font-size: 12px; border:1px solid #ECAB2F; text-align:center; line-height: 20px; border-radius: 5px; color: #ECAB2F; margin-right: 15px;}

/* 视频弹层 */
.popbg{ background: rgba(0, 0, 0, 0.8); left: 0; top:0; bottom: 0; right: 0; z-index: 10; position:fixed;}
.videobox{ width:1123px; height: 570px; position:fixed; top:50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);}
.videobox video{ width: 100%; height: 100%;}
.video-close{ position: absolute; top:0; right: 0; width: 24px; height: 24px; background: url(../../assets/images/video-close.png) no-repeat; cursor: pointer; z-index: 5;}



:global(.slick-slider)
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

:global(.slick-list)
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
:global(.slick-list:focus)
{
    outline: none;
}
:global(.slick-list.dragging)
{
    cursor: pointer;
    cursor: hand;
}

:global(.slick-slider .slick-track,
.slick-slider .slick-list)
{
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

:global(.slick-track)
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
:global(.slick-track:before,
.slick-track:after)
{
    display: table;

    content: '';
}
:global(.slick-track:after)
{
    clear: both;
}
:global(.slick-loading .slick-track)
{
    visibility: hidden;
}

:global(.slick-slide)
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
:global([dir='rtl'] .slick-slide)
{
    float: right;
}
:global(.slick-slide img)
{
    display: block; width:100%;
}
:global(.slick-slide.slick-loading img)
{
    display: none;
}
:global(.slick-slide.dragging img)
{
    pointer-events: none;
}
:global(.slick-initialized .slick-slide)
{
    display: block;
}
:global(.slick-loading .slick-slide)
{
    visibility: hidden;
}
:global(.slick-vertical .slick-slide)
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
:global(.slick-arrow.slick-hidden) {
    display: none;
}
:global(.banner .slick-slider .slick-arrow){ display: none !important;}
:global(.banner .slick-dots){position:absolute;bottom:30px;left:0;width:100%;height:20px;text-align:center;}
:global(.banner .slick-dots li){display:inline-block;width:20px;height:20px;background:url(../../assets/images/icon.png) no-repeat;background-position:0px -180px;margin:0 15px;}
:global(.banner .slick-dots li button){ width:20px; height: 20px; opacity: 0; cursor: pointer;}
:global(.banner .slick-dots .slick-active){background-position:-21px -180px}
:global(.teacher-list .slick-arrow){ width: 66px; height: 125px;}
:global(.teacher-list .slick-prev){position:absolute;left:30px;top:50%;margin-top:-83px;width:66px;height:125px;background:url(../../assets/images/arrow-l.png) no-repeat; z-index: 10; border:none; text-indent: -9999px; outline:none}
:global(.teacher-list .slick-next){position:absolute;right:0;top:50%;margin-top:-83px;width:66px;height:125px;background:url(../../assets/images/arrow-r.png) no-repeat;  border:none; text-indent: -9999px; outline:none}
:global(.teacher-list .slick-dots){ height: 20px; text-align: center; padding: 60px 0 30px;}
:global(.teacher-list .slick-dots li){display:inline-block;width:20px;height:20px;background:url(../../assets/images/icon.png) no-repeat;background-position:-46px 0;margin:0 15px; cursor: pointer; float:none;}
:global(.teacher-list .slick-dots .slick-active){ background-position:-71px -48px}
:global(.teacher-list .slick-dots li button){ width:20px; height: 20px; opacity: 0;}

:global(.pc-banner){width:100%;float:left}
:global(.pc-banner .buts .swiper-button-prev){ left:10%;}
:global(.pc-banner .buts .swiper-button-next){ right:10%;}
@media screen and (max-width:668px){
    :global(.pc-banner){background-size:auto 100%}
}
:global(.swiper-container){width:100%;}
@media screen and (max-width:668px){
    :global(.swiper-container){margin:20px 0 15px}
}
:global(.swiper-container .swiper-slide){-webkit-transition:transform 1s;-moz-transition:transform 1s;-ms-transition:transform 1s;-o-transition:transform 1s;-webkit-transform:scale(.9);transform:scale(.9)}
@media screen and (max-width:668px){
    :global(.swiper-container .swiper-slide){-webkit-transform:scale(.97);transform:scale(.97)}
}
:global(.swiper-container .swiper-slide-active){-webkit-transform:scale(1);transform:scale(1)}
:global(.swiper-container .swiper-slide-duplicate-active){-webkit-transform:scale(1);transform:scale(1)}
@media screen and (max-width:668px){
    :global(.swiper-slide-active,.swiper-slide-duplicate-active){-webkit-transform:scale(.97);transform:scale(.97)}
}
:global(.none-effect){-webkit-transition:none;-moz-transition:none;-ms-transition:none;-o-transition:none}
:global(.swiper-container .swiper-slide a){background:#fff;padding:10px;display:block;border-radius:14px}
@media screen and (min-width:668px){
    :global(.swiper-container .swiper-slide a:after){position:absolute;top:0;left:0;display:block;box-sizing:border-box;border:10px solid #fff;content:"";width:100%;height:100%;border-radius:20px}
}
:global(.swiper-container .swiper-slide-active a:after){background:0 0}
@media screen and (max-width:668px){
    :global(.swiper-container .swiper-slide a){padding:5px;border-radius:7px}
}
:global(.swiper-container .swiper-slide img){width:100%;border-radius:0;display:block}
@media screen and (max-width:668px){
    :global(.swiper-container .swiper-slide img){border-radius:7px}
}
:global(.swiper-pagination){position:relative;margin-bottom:30px}
:global(.swiper-pagination-bullet){background:#00a0e9;margin-left:10px;margin-right:10px;width:17px;height:17px;opacity:1;}
:global(.swiper-pagination-bullet-active){width:13px;height:13px;background:#fff;border:6px solid #00a0e9;margin-bottom:0}
@media screen and (max-width:668px){
    :global(.swiper-pagination){position:relative;margin-bottom:20px}
    :global(.swiper-pagination-bullet){background:#00a0e9;margin-left:2px;margin-right:2px;width:8px;height:8px;margin-bottom:2px}
    :global(.swiper-pagination-bullet-active){width:6px;height:6px;background:#fff;border:3px solid #00a0e9;margin-bottom:0}
}
:global(.swiper-container .button){width:1000px;margin:0 auto;bottom:43px;position:relative}
@media screen and (max-width:668px){
    :global(.swiper-container .button){width:70%;bottom:22px}
}
:global(.swiper-container .button div:hover){background-color:#2f4798}
:global(.swiper-button-prev){width:40px;height:40px;border-radius:50%;background:url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l4.2%2C4.2L8.4%2C22l17.8%2C17.8L22%2C44L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") #00a0e9 center 1% 50% no-repeat; z-index: 5; cursor: pointer;}
:global(.swiper-button-next){width:40px;height:40px;border-radius:50%;background:url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L5%2C44l-4.2-4.2L18.6%2C22L0.8%2C4.2L5%2C0z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") #00a0e9 center 1% 50% no-repeat; z-index: 5; cursor: pointer;}
@media screen and (max-width:668px){
    :global(.button div){width:28px;height:28px}
}

:global(.swiper-container2){
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
:global(.swiper-container2 .swiper-slide) {
    width: 217px;
    text-align: center;
    font-size: 18px;
}
:global(.swiper-pagination){ position:static; bottom:0; margin-top:30px;}
:global(.swiper-pagination-bullet){display:inline-block;width:20px;height:20px;background:url(../../assets/images/icon.png) no-repeat;background-position:0 -180px;margin-left:25px; opacity: 1; cursor:pointer;}
:global(.swiper-pagination-bullet-active){background-position:-21px -180px; border:none; outline:0;}
:global(.swiper-button-prev){position:absolute;left:0;top:50%;margin-top:-63px;width:66px;height:125px;background:url(../../assets/images/arrow-l1.png) no-repeat; border-radius: 0;}
:global(.swiper-button-next){position:absolute;right:0;top:50%;margin-top:-63px;width:66px;height:125px;background:url(../../assets/images/arrow-r1.png) no-repeat; border-radius: 0;}
:global(.swiper-button-prev:hover){background:url(../../assets/images/arrow-l1-on.png) no-repeat;}
:global(.swiper-button-next:hover){background:url(../../assets/images/arrow-r1-on.png) no-repeat;}
:global(.swiper-button-prev-white){position:absolute;right:0;top:50%;margin-top:-103px;width:66px;height:125px;background:url(../../assets/images/arrow-l2.png) no-repeat; border-radius: 0;}
:global(.swiper-button-next-white){position:absolute;right:0;top:50%;margin-top:-103px;width:66px;height:125px;background:url(../../assets/images/arrow-r2.png) no-repeat; border-radius: 0;}
:global(.swiper-button-prev-white:hover){background:url(../../assets/images/arrow-l2-on.png) no-repeat;}
:global(.swiper-button-next-white:hover){background:url(../../assets/images/arrow-r2-on.png) no-repeat;}
:global(.swiper-pagination3){ position: absolute; top:0; right: 0; height: 20px; text-align: right;}
:global(.swiper-pagination3 .swiper-pagination-bullet){display:inline-block;width:20px;height:20px;background:url(../../assets/images/icon.png) no-repeat;background-position:0 -180px;margin-left:25px; opacity: 1;}
:global(.swiper-pagination3 .swiper-pagination-bullet-active){background-position:-21px -180px; border:none;}
