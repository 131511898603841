.main{ width: 1200px; margin: 0 auto;}
.bgff{ background: #F0F0F0;}
.banner1{vertical-align:top; height: 340px; background: url(../../assets/images/banner-service1.png) no-repeat center top;}
.banner2{vertical-align:top; height: 468px; background: url(../../assets/images/banner-service2.png) no-repeat center top;}
.banner img{ width: 100%;}

.serbg1{ background: url(../../assets/images/serbg1.png) no-repeat center top; height: 455px;}
.countries{ overflow: hidden; margin:0px -30px 0;}
.countries img{float: left; margin-left: 30px;}

.recom{overflow: hidden; width: 1200px; padding-bottom: 35px;}
.recom .row{ margin-left: -30px; border-bottom: 1px solid #e0e0e0; overflow: hidden;}
.recom .row:hover{border-bottom: 1px solid #F0B645;}
.recom .item{ width: 216px; color: #ffffff; float: left; line-height: 30px; margin-left:30px;}
.recom .item .pic{ height: 102px; position: relative; overflow: hidden;}
.recom .pic img{ width: 100%; height: 100%;}
.recom .pic .mark{ font-size: 18px; position: absolute; left:0; top:0; width: 100%; height: 100%; text-align: center; display: flex; flex-direction: column; justify-content: center;}
.recom .pic .mark span{ font-size: 16px;}
.recom .pic .btn{ position: absolute; left: 0; bottom: -40px; width:100%; height: 40px; line-height: 40px; font-size: 18px; background: #F0B645; text-align: center; color: #333333; font-weight: bold; transition: .3s;}
.recom .item  .line{ width: 70px; height: 6px; margin:30px auto 0; background: #F0F0F0;}
.recom .item:hover .mark{ display: none;}
.recom .item:hover .btn{ bottom: 0px;}
.recom .item:hover .line{ background: #F0B645; border-radius: 3px;}

.lxser{ background: url(../../assets/images/sbg2.jpg) no-repeat right top #ffffff; height: 396px; overflow: hidden; margin-bottom: 50px;}
.lxser .left{ width: 461px; height: 395px; float: left;}
.lxser .left img{ width: 100%; height: 100%;}
.lxser .right{ width: 710px; float: right;}
.lxser .right .tit{ padding:40px 0 18px; border-bottom: 1px solid #F0B645;}
.serlist{ margin-left: -80px; padding-top:50px;}
.serlist li{ float: left; height: 39px; margin:0 0 15px 80px;}
.serlist li:nth-child(odd){ width: 273px;}
.serlist li:nth-child(even){ width: 309px;}
.serlist .item1{ background: url(../../assets/images/serli1.png) no-repeat;}
.serlist .item2{ background: url(../../assets/images/serli2.png) no-repeat;}
.serlist .item3{ background: url(../../assets/images/serli3.png) no-repeat;}
.serlist .item4{ background: url(../../assets/images/serli4.png) no-repeat;}
.serlist .item5{ background: url(../../assets/images/serli5.png) no-repeat;}
.serlist .item6{ background: url(../../assets/images/serli6.png) no-repeat;}
.serlist .item7{ background: url(../../assets/images/serli7.png) no-repeat;}
.serlist .item8{ background: url(../../assets/images/serli8.png) no-repeat;}
.serlist .item1:hover{ background: url(../../assets/images/serli1-on.png) no-repeat;}
.serlist .item2:hover{ background: url(../../assets/images/serli2-on.png) no-repeat;}
.serlist .item3:hover{ background: url(../../assets/images/serli3-on.png) no-repeat;}
.serlist .item4:hover{ background: url(../../assets/images/serli4-on.png) no-repeat;}
.serlist .item5:hover{ background: url(../../assets/images/serli5-on.png) no-repeat;}
.serlist .item6:hover{ background: url(../../assets/images/serli6-on.png) no-repeat;}
.serlist .item7:hover{ background: url(../../assets/images/serli7-on.png) no-repeat;}
.serlist .item8:hover{ background: url(../../assets/images/serli8-on.png) no-repeat;}

.major{ background: #000000;}
.majorList{overflow: hidden; width: 1200px; padding-bottom: 35px;}
.majorList .row{ margin:-30px 0 0 -22px; overflow: hidden;}
.majorList .item{ width: 222px; color: #ffffff; float: left; line-height: 30px; margin:30px 0 0 22px;}
.majorList .item .pic{ height: 217px; position: relative; overflow: hidden; box-sizing: border-box;}
.majorList .pic img{ width: 100%; height: 100%;}
.majorList .pic .mark{ font-size: 18px; position: absolute; left:0; top:0; width: 100%; height: 100%; text-align: center; display: flex; flex-direction: column; justify-content: center;}
.majorList .pic .mark span{ font-size: 16px;}
.majorList .pic .btn{ position: absolute; left: 0; bottom: 0px; width:100%; height: 40px; line-height: 40px; font-size: 18px; background:rgba(0, 0, 0, .3); text-align: center; color: #ffffff; transition: .3s;}
.majorList .item  .line{ width: 70px; height: 6px; margin:30px auto 0;}
.majorList .item:hover .mark{ display: none;}
.majorList .item:hover .btn{ background: #F0B645; font-weight: bold;}
.majorList .item:hover .line{ background: #F0B645; border-radius: 3px;}
.majorList .item:hover .pic{ border:1px solid #F0B645;}

.offershow{ background: #242321;}
.offerlist{ width: 1200px; overflow: hidden;}
.offerlist ul{ margin-left: -30px;}
.offerlist li{ width: 380px; height: 270px; box-sizing: border-box; overflow: hidden; float: left; margin-left:30px; position: relative; cursor: pointer;}
.offerlist li img{ width: 100%; height: 100%;}
.offerlist li:hover{ border:1px solid #F0B645;}
.offerlist li:hover .jj{ display: none;}
.offerlist li:hover .xx{ display: block;}
.offerlist li:hover .info{ display: block;}
.offerlist :global(.swiper-pagination-bullets .swiper-pagination-bullet){ margin:0 15px; outline: 0;}
.offerlist .jj{ height: 60px; line-height: 60px; background: rgba(0, 0, 0, .5); position: absolute; left: 0; bottom: 0;  width: 380px; font-size: 20px; padding-left: 30px; box-sizing: border-box; color: #ffffff;}
.offerlist .jj strong{ margin-right: 20px;}
.offerlist .xx{ height: 60px; line-height: 60px; text-align: center; font-weight: bold; color: #333333; position: absolute; left:0; bottom: 0; width: 380px; font-size: 20px; background: #F0B645; z-index: 1; display: none;}
.offerlist .info{position: absolute; width: 380px;height: 270px; left:0; top:0; background: rgba(0, 0, 0, .5); color: #ffffff; font-size: 18px; text-align: center; box-sizing: border-box; padding-top:30px; line-height: 35px; display: none;}
.offerlist .info .line{ display: inline-block; width: 39px; height: 5px; background: #F0B645; vertical-align: top; margin-top:15px;}
.offerlist .info .name{ font-size: 20px; font-weight: bold;}

.other{ margin-left: -30px; overflow: hidden;}
.other .item{ width: 278px; height: 180px; background: #F0B645; font-size: 18px; color: #333333; margin-left: 29px; float: left; text-align: center; display: flex; flex-direction: column; justify-content: center; align-items: center; box-sizing: border-box; cursor: pointer;}
.other .item i{ display: inline-block; width: 42px; height: 34px;}
.other .item1 i{ background:url(../../assets/images/otherIcon1.png) no-repeat center;}
.other .item2 i{ background:url(../../assets/images/otherIcon2.png) no-repeat center;}
.other .item3 i{ background:url(../../assets/images/otherIcon3.png) no-repeat center;}
.other .item4 i{ background:url(../../assets/images/otherIcon4.png) no-repeat center;}
.other .item p{ margin:15px 0 15px;}
.other .item .line{ display: inline-block; width: 43px; height: 4px; background: #ffffff;}
.other .on{ background: #ffffff; border:1px solid #F0B645;}
.other .on .line{ background: #F0B645;}
.other .item1.on i{ background:url(../../assets/images/otherIcon1-on.png) no-repeat center;}
.other .item2.on i{ background:url(../../assets/images/otherIcon2-on.png) no-repeat center;}
.other .item3.on i{ background:url(../../assets/images/otherIcon3-on.png) no-repeat center;}
.other .item4.on i{ background:url(../../assets/images/otherIcon4-on.png) no-repeat center;}
.vip{ overflow: hidden; padding-bottom: 50px;}
.otherlist{ box-sizing: border-box; width: 1200px; background: #ffffff; border:1px solid #F0B645; padding:15px 30px; clear: both;margin-top: 30px; overflow: hidden;}
.otherlist .row{ border-bottom: 1px solid #F0F0F0; padding-bottom:5px; margin-bottom: 5px; overflow: hidden;}
.otherlist .item{ font-size: 14px; color: #666666; height: 42px; line-height: 42px; width: 379px; box-sizing: border-box; padding-left:40px; background:url(../../assets/images/yuanquan.png) no-repeat 20px center; float: left;}
.otherlist .item:hover{ color: #ffffff; background:url(../../assets/images/yuanquan-on.png) no-repeat 20px center #F0B645;}

.popbg{ background: rgba(0, 0, 0, 0.8); left: 0; top:0; bottom: 0; right: 0; z-index: 10; position:fixed;}
.offerbox{ width: 400px; position: fixed; left: 50%; top:50%; transform: translate(-50%, -50%);}
.offerbox img{ width: 100%;}
:global(.pop-close){ position: absolute; top:20px; right: 20px; width: 32px; height: 32px; background: url(../../assets/images/close4.png) no-repeat; background-size: contain; cursor: pointer; z-index: 5;}
