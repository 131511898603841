.bg{ background: #F0F0F0;}
.collegesCard{ height: 120px; background: url(../../assets/images/school-detail.png) no-repeat center top; font-size: 20px; color: #666666; padding:50px 0; line-height: 40px;}
.collegesPic{width: 120px; height: 120px; float: left;}
.collegesTit{ margin-left: 150px; display: flex; flex-direction: column; justify-content: center; height: 120px;;}
.collegesTit .tit{ font-size: 26px; color: #333333}
.collegesTit .info{ margin-left: -10px;}
.collegesTit span{ margin:0 10px;}
.collItems{ overflow: hidden; padding-top:25px;}
.leftArea{ width: 200px; float: left; background: #ffffff;}
.rightArea{ width: 980px; float: right;}
.leftArea span{ display: block; height: 90px; line-height: 90px; border-bottom: 1px solid #F0F0F0; font-size: 18px; color: #666666; text-align: center;}
.leftArea .on{ color: #F0B645; border-left: 6px solid #F0B645;}
.rightArea .blank{ background: #ffffff; padding:30px 40px 50px; font-size: 16px;  line-height:30px; color: #333333; position: relative; margin-bottom: 25px;}
.rightArea .blank .name{ font-size: 18px; color: #E7B044; padding-bottom: 15px;}
.arrow{ position: absolute; bottom: 20px; left: 50%; margin-left: -6px; width: 12px; height: 7px; background:url(../../assets/images/xiala.png) no-repeat;}