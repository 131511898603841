.bgf0{ background: #F0F0F0;}
.banner1{ height: 163px; background: url(../../assets/images/gjss_03.jpg) no-repeat center top;}
.banner2{ height: 164px; background: url(../../assets/images/gjss_05.jpg) no-repeat center top;}
.banner3{ height: 163px; background: url(../../assets/images/gjss_06.jpg) no-repeat center top;}
.banner4{ height: 163px; background: url(../../assets/images/gjss_07.jpg) no-repeat center top;}
.banner5{ height: 164px; background: url(../../assets/images/gjss_08.jpg) no-repeat center top;}
.banner6{ height: 163px; background: url(../../assets/images/gjss_09.jpg) no-repeat center top;}

.sblank{ width: 1100px; margin:0 auto;font-size: 14px; color: #666666; background: #ffffff; margin-bottom: 50px; overflow: hidden;}
.sblank .item i{ display: inline-block; width: 58px; height: 43px;}
.sblank .item .icon1{ background:url(../../assets/images/ac-icon1.png) no-repeat left center; background-size: contain;}
.sblank .item .icon2{ background:url(../../assets/images/ac-icon2.png) no-repeat left center; background-size: contain;}
.sblank .item .icon3{ background:url(../../assets/images/ac-icon3.png) no-repeat left center; background-size: contain;}
.sblank .item .icon4{ background:url(../../assets/images/ac-icon4.png) no-repeat left center; background-size: contain;}
.sblank .item .icon5{ background:url(../../assets/images/ac-icon5.png) no-repeat left center; background-size: contain;}
.sblank .item .name{ font-size: 18px; padding:10px 0 5px;}
.sblank .item .line{ width:58px; height:6px; background:#666666; margin-top:10px;}
.sblank .item{ float: left; padding:43px 0 0 35px; height: 228px; box-sizing: border-box; border-right: 1px solid #F0F0F0;}
.sblank .item:hover{ background:#F0B645; color: #333333;}
.sblank .item:hover .line{ background: #ffffff;}
.linethree{ border-bottom: 1px solid #F0F0F0; overflow: hidden;}
.linethree .item{ width: 33.33333%;}
.linethree .item:nth-last-child(1){ border-right: none;}
.linetwo .item{ width: 50%;}
.linetwo .item:nth-last-child(1){ border-right: none;}

.matchbg{ background: #ffffff; padding-top: 10px;}
.match{ height: 536px;}
.match1{ background: url(../../assets/images/gjss_15.jpg) no-repeat center top;}
.match2{ background: url(../../assets/images/gjss_17.jpg) no-repeat center top;}
.match3{ background: url(../../assets/images/gjss_19.jpg) no-repeat center top;}
.match4{ background: url(../../assets/images/gjss_21.jpg) no-repeat center top;}
.match5{ background: url(../../assets/images/gjss_23.jpg) no-repeat center top;}
.matchwrap{ width: 1200px; height: 100%; margin: 0 auto; position: relative;}
.matchwrap .video{ width: 606px; height: 305px; position: absolute;}
.matchwrap .video img{ width: 100%; height: 100%;}
.matchwrap .btn{ position: absolute; width: 178px; height: 60px; box-sizing: border-box; border:1px solid #ffffff; line-height: 58px; text-align: center; font-size: 20px; color: #ffffff; border-radius: 30px;}
.matchwrap .btn:hover{ background: #F0B645;}
.matchright .video{ right: 50px; top: 50px;}
.matchright .btn{ left: 60px; bottom: 50px;}
.matchleft .video{ left: 60px; top: 50px;}
.matchleft .btn{ right: 50px; bottom: 50px;}