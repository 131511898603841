/* 详情 */
.banner{position: relative;}
.banner img{ width: 100%;}
.bgf0{ background: #F0F0F0;}
.position{ font-size: 16px; color: #666666; padding:25px 0; border-bottom: 1px solid #F0F0F0;}
.position em{ color:#F0B645;}
.position a{ color: #666666;}
.article{ padding:30px 0;}
.article::after{ content: '.'; height: 0; overflow: hidden; clear: both; visibility: hidden;display: block;}
.article .left{ width:920px; box-sizing: border-box; float: left; padding:0 0px 0 30px; background: #ffffff;}
.article .right{float: right; width: 250px}
.match_article{ width:100%;}
.about{ width:23%; float: right;}
h1{ font-size:30px; color:#333333; font-weight:normal; line-height: 40px; padding:20px 0 0px; width: 832px;}
.article .source{ font-size:14px; color: #666666; padding-top: 8px;}
.article .source span{ padding:0 5px; color: #2A7497;}
.article .source p{ display: inline-block; color: #666666;}
.article .intr{ font-size: 18px; color: #333333; font-weight: bold; width: 860px; box-sizing: border-box; background: #f0f0f0; padding:20px; line-height: 30px; margin-top: 10px;}
.content{ font-size: 14px; color: #333333; line-height: 30px; width: 860px; box-sizing: border-box; margin-top:20px; overflow: hidden;}
.content iframe{ height: 330px; width: 50%; display: block; margin:0 auto;}
.content p{ text-indent: 2em; line-height: 40px;}

.blankwhite{ background: #ffffff; margin-bottom: 30px; padding:0 0 35px 25px;}
.blankwhite .tit{ padding:30px 0 20px; font-size:14px; color:#E18680; border-bottom: 1px solid #F0F0F0;}
.blankwhite .tit img{ width: 94px; height: 19px; margin-right: 25px;}
.blankwhite .tags{ padding-top:6px;}
.blankwhite .tags span{ display: inline-block; width: 80px; height: 24px; text-align: center; line-height: 24px; font-size: 12px; color: #666666; background: #F9F9F9; margin:14px 14px 0 0; border-radius: 12px;}
.blankwhite .tags span:hover{ color: #333333; background: #E18680; font-weight: bold;}
.blankwhite .videos{ margin-top: -15px; padding-bottom: 20px;}
.videos .item{ width: 206px; background: #FAFAFA; font-size: 12px; color: #666666; overflow: hidden;  margin-top: 35px; padding-bottom: 15px;}
.videos .item:hover{box-shadow: 0px 0px 20px 0 #FAFAFA;}
.videos .item .pic{ width: 206px; height: 110px; position: relative;}
.videos .item .pic img{ width: 100%; height: 100%;}
.videos .item .pic .playbtn{ width: 33px; height: 33px; background: url(../../assets/images/play-btn2.png) no-repeat; background-size: contain; position:absolute; bottom:10px; right: 10px; cursor: pointer;}
.videos .item .title{ color: #333333;margin:15px 19px 0;}
.videos .item .line{ padding: 0 19px;}

.aboutnews{ overflow: hidden; padding:10px 0 10px 25px; margin-left:-25px;}
.padT20{ margin-top: 20px;}
.aboutnews:hover{ background: #f0f0f0;}
.aboutnews .anleft{ width: 12px; float: left;}
.aboutnews .anleft span{ display: inline-block; width: 12px; height: 12px; font-size: 12px; color: #333333; background: #F0B645; line-height: 12px; text-align: center;}
.aboutnews .text{ width: 107px; margin: 0 10px 0 3px; font-size: 12px; line-height: 18px; color: #333333; float: left;}
.aboutnews .text .bt{ height: 36px; display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;}
.aboutnews .text a{ color: #333333; height: 36px; display: block; display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;}
.aboutnews .text .time{ color: #666666;}
.aboutnews .pic{ width: 82px; height: 52px; float: left;}
.aboutnews .pic img{ width: 100%; height: 100%;}

.block{ margin-bottom:30px;}
.block .title{ height: 60px; line-height: 60px; font-size:18px; color: #999999; text-align: center;}
.block .title img{ margin:20px 10px 0 0;}
.block ul{ border-top:1px solid #E5E5E5; padding:0 40px 10px;}
.block li{ font-size:14px; color:#666666;  border-bottom:1px solid #E5E5E5; padding:15px 0;}
.block li:nth-last-child(1){ border-bottom: none;}
.block li a{ color: #333333;}
.evaluate{ padding:0 22px; box-shadow:0px 0px 14px 0px rgba(0, 0, 0, 0.1); padding-bottom: 60px;}
.evaluate .title{ color: #F0B645;}
.evaluate .item{ height: 44px; line-height: 44px; background: #EEEEEE; font-size: 14px; color: #666666; margin-bottom: 10px;}
.evaluate .item::after{content: ".";display: block;clear: both;visibility: hidden;line-height: 0;height: 0;}
.evaluate .item .name{ display: inline-block; margin-left:20px; float: left;}
.evaluate .item input{ display: inline-block; height: 44px; line-height: 44px; width: 135px; float: left;}
.evaluate .btn{ display: block; background: #F0B645; font-size: 14px; color: #ffffff; text-align: center; height: 44px; line-height: 44px;}
.evaluate .drop-down{ float: left; width: 165px; margin-left:5px; position: relative;}
.evaluate .drop-down span{}
.evaluate .drop-down .list{ background-color: #A2A2A2; padding: 0; position: absolute; right: -80px; top: 50px; z-index: 2; overflow-y: auto; width:250px; max-height: 300px; }
.evaluate .drop-down .list li{ height: 40px; line-height: 40px; overflow: hidden; padding: 0;; background-color: #A2A2A2; border-bottom: none; padding:0 10px;}
.evaluate .drop-down .list li:hover{ background: #E5E5E5; color: #666666;}
.rec{ box-shadow:0px 0px 14px 0px rgba(0, 0, 0, 0.1);}