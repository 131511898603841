.bg{ background: #f7f7f7;}
.main{ width:1200px; margin: 0 auto;}
.banner1{ background: url(../../assets/images/ru_02.jpg) no-repeat center top; height: 104px;}
.banner2{ background: url(../../assets/images/ru_03.jpg) no-repeat center top; height: 104px;}
.banner3{ background: url(../../assets/images/ru_04.jpg) no-repeat center top; height: 104px;}
.banner4{ background: url(../../assets/images/ru_05.jpg) no-repeat center top; height: 105px;}
.banner5{ background: url(../../assets/images/ru_06.jpg) no-repeat center top; height: 104px;}
.banner6{ background: url(../../assets/images/ru_07.jpg) no-repeat center top; height: 104px;}
.pay1{ background: url(../../assets/images/ru_17.jpg) no-repeat center top; height: 145px;}
.pay2{ background: url(../../assets/images/ru_18.jpg) no-repeat center top; height: 145px;}
.pay3{ background: url(../../assets/images/ru_19.jpg) no-repeat center top; height: 145px;}
.pay4{ background: url(../../assets/images/ru_20.jpg) no-repeat center top; height: 145px;}
.pay5{ background: url(../../assets/images/ru_21.jpg) no-repeat center top; height: 145px;}
.pay6{ background: url(../../assets/images/ru_22.jpg) no-repeat center top; height: 145px;}