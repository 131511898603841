.wrap{ width:1200px; margin:0 auto;}
.bgf6{ background: #F6F6F6; min-height: 100%;}
.banner{ background: #ffffff; width:1200px; padding:0 33px;box-sizing:border-box; margin:30px auto 0;}
.banner-show{ box-sizing:border-box; padding-bottom: 30px; overflow:hidden;}
.banner-right{ width:480px; float: right;}
.banner-left{ width:594px; height: 271px; float: left;}
.banner-left img{ width:100%; height: 100%;}
.banner-right dl{ overflow:hidden;}
.banner-right dt{ width: 80px; height: 80px; float: left; border-radius: 50%; overflow: hidden; margin-right: 12px; }
.banner-right dt img{ width: 100%; height: 100%;}
.banner-right dd{ font-size: 18px; color: #a1a1a1; line-height: 28px;}
.banner-right .tit{ color: #333333; padding-bottom:10px;}
.banner-right .sta{ color:#595959; padding:2px 0 0 92px;}
.banner-right .ongoing{ display: inline-block; color: #ffffff; width:84px; height: 26px;line-height: 26px; font-size: 14px; background: #E18680; text-align: center; border-radius: 3px;}
.banner-right .end{ display: inline-block; color: #ffffff; width:84px; height: 26px;line-height: 26px; font-size: 14px; background: #999999; text-align: center; border-radius: 3px;}
.banner-right .sta .fee{ font-size: 14px; color: #333333; margin-left: 20px;}
.banner-right .sta .fee em{ font-size: 12px; color: #E7B044; margin-left: 5px;}

.codetwo{ padding: 20px 0 0 86px;}
.codetwo .codeone{ width: 106px; text-align: center; font-size: 14px; color: #333333; margin-right: 35px; display: inline-block;}
.codetwo .codeone img{ width: 92px; height: 90px; margin-bottom: 16px;}

.position{ font-size: 16px; color: #666666; padding:23px 0; border-bottom: 1px solid #F0F0F0; margin-bottom: 20px;}
.position em{ color:#F0B645;}
.position a{ color: #666666;}

.lives{ overflow: hidden;  border-top:1px solid #F0F0F0; padding:26px 0;}
.livesbtn{ display: block; width: 84px; height: 25px; font-size: 14px; color: #E7B044; line-height: 21px; box-sizing: border-box; border:2px solid #E7B044; float: left; border-radius: 5px; text-align: center;}
.livesbtn img{ vertical-align: top; margin:5px 0 0 5px;}
.liveslist{ float: left; font-size: 14px; color: #666666; overflow: hidden; margin-right: -70px; width: 1050px; }
.liveslist .item{ float: left; width:410px; height: 30px; line-height: 30px; padding:0 80px; background:url(../../assets/images/sx.png) no-repeat right center;}
.liveslist .item em{ display: inline-block; width: 333px; height: 30px; line-height: 30px; overflow: hidden;}
.liveslist .item:hover{ color: #E7B044;}
.liveslist .item:hover span{ color: #E7B044;}
.liveslist .item span{ color: #999999; vertical-align: top; display: inline-block;}
.liveslist .item .on{ color: #E7B044;}
.liveslist .on{ color:#E7B044;}
.liveslist .marright{ margin-right: -90px; margin-left: 0px;}

.live-tab{ overflow: hidden;}
.live-tab .item{ width: 600px; height: 168px; background: #ffffff; float: left; text-align:center; font-size: 28px; color: #606060; position:relative; cursor: pointer;}
.live-tab .item i{ display: inline-block; width:72px; height: 56px; background:url(../../assets/images/icon.png) no-repeat; margin:32px 0 12px;}
.live-tab .item .icon1{ background-position: 0 -200px;}
.live-tab .item .icon2{ background-position: -72px -200px;}
.live-tab .on{ color: #ffffff; background: #FDC03F;}
.live-tab .on .icon1{ background-position: 0 -256px;}
.live-tab .on .icon2{ background-position: -72px -256px;}
.triangle{ position: absolute; bottom:0; left:50%; margin-left:-10px; width: 0px; height: 0px; border-bottom:15px solid #ffffff; border-left:10px solid transparent; border-right:10px solid transparent;}
.live-con{ height: 280px;}
.live-state{ font-size: 28px; color: #929191; float: left; width:580px; height: 136px; padding:40px 30px; text-align: right; box-sizing: border-box; background: url(../../assets/images/zb_03.png) no-repeat; float: left; box-shadow: 0 0 10px #e6e6e6; margin-top:75px;}
.live-state p{ text-align: left; height: 30px; line-height: 30px;}
.live-state span{ font-size: 22px; display: inline-block; height: 22px; margin-top: 20px; background: url(../../assets/images/jt1.png) no-repeat right center; padding-right: 28px; cursor:pointer;}
.fr{ float: right;}
.live-state.on{ color: #474747;}
.live-state.on span{ color: #F0B645; background: url(../../assets/images/jt2.png) no-repeat right center;}
.code{ text-align: center;}
.code li{ display: inline-block; width: 302px; height: 254px; margin:12px 140px 0;}

/* 赛事视频 */
.whiteblank{ background: #ffffff; padding:40px 33px 120px; width: 1200px; margin:0 auto; box-sizing: border-box;}
.video-sslist{  overflow: hidden; padding:50px 0 30px; margin:0 0 50px -26px; border-bottom: 1px solid #F0F0F0;}
.video-sslist .item{ width: 206px; float: left; padding-bottom: 20px; margin-left: 26px; font-size: 12px; color: #333333; position: relative;}
.video-sslist .item:first-of-type{ width: 438px; padding-bottom:0;}
.video-sslist .item:first-of-type img{ height: 320px; }
.video-sslist .item:first-of-type p{ height: 20px; margin-bottom: 10px;}
.video-sslist .item:first-of-type .playbtn{ width: 68px; height: 68px; margin:-50px 0 0 -34px;}
.video-sslist .item img{ width: 100%; height: 110px;}
.video-sslist .item p{ margin-top:15px; line-height: 20px; height: 40px; overflow: hidden;}
.video-sslist .item .playbtn{ width: 33px; height: 33px; background: url(../../assets/images/play-btn3.png) no-repeat; background-size: contain; position: absolute; top: 50%; left: 50%; margin:-50px 0 0 -15px; cursor:pointer;}

/* 赛事资讯 */
.zx-list{ padding:50px 0 25px; overflow: hidden; margin:0 -65px;}
.borderbot{ width: 1144px; margin: 0 auto; border-bottom: 1px solid #f0f0f0; overflow: hidden;}
.zx-list .item{ font-size: 14px; width: 500px; float: left; padding:0 65px; margin-bottom: 24px; color: #333333; border-right: 1px solid #F0F0F0;}
.zx-list .item .left{ width: 140px; height: 88px; float: left;}
.zx-list .item .left img{ width: 100%; height: 100%;}
.zx-list .item .right{ width: 338px; float:right;}
.zx-list .item .right p{ line-height: 20px; height: 40px; overflow: hidden;}
.zx-list .item .right span{ font-size: 12px; display: inline-block; padding-top: 25px;}
.zx-list .item a{ color: #333333;}

.tabtit{ border-bottom: 1px solid #f0f0f0; padding:50px 0 0px; font-size: 16px; color: #666666;}
.tabtit span{ margin-right: 57px; display: inline-block; height: 20px; line-height: 20px; cursor: pointer;}
.tabtit .on{ font-size: 20px; color: #E7B044; border-bottom: 3px solid #E7B044; padding-bottom: 20px;}
.moreinfo{ font-size: 14px; color: #333333; line-height: 25px; padding-top:20px;}

.rater{ padding:50px 0;}
.title{ font-size: 20px; color: #333333; height: 20px; line-height: 20px; overflow: hidden;}
.rater-list{ padding-top:55px; overflow: hidden; margin-left: -20px;}
.rater-list .juryitem{ float: left; text-align: center; font-size: 14px; color: #474747; line-height: 26px; background: #ffffff; padding-bottom: 10px; margin:0 0 20px 20px;}
.rater-list .juryitem:hover{ color: #F0B645;}
.rater-list .juryitem img{ width:100%; height: 185px; margin-bottom: 10px;}
.rater-list .num{ padding:60px 0 0 0; text-align: center; font-size: 0; clear: both;}
.rater-list .num span{ display: inline-block; width: 40px; height: 4px; background: #F1F0F0; margin:0 5px; cursor:pointer;}
.rater-list .num .on{ background: #F0B645;}
.introduce{ width:1200px; line-height: 28px; border:2px solid #F0B645; font-size: 20px; color: #A0A0A0; background: #ffffff; border-radius:10px; padding:35px 33px 45px; box-sizing: border-box; position: relative;}
.introduce .intrblank{ overflow : hidden; height: 120px; position: relative;}
.introduce .intrblank::after{content: ''; position:absolute; bottom:0; right:0;}
.introduce.on .intrblank{ height: auto;}
.introduce.on .intrblank::after{content: '';}
.introduce:hover{ color: #F0B645;}
.introduce .downarrow{ position: absolute; left:50%; bottom: 16px; width:24px; height: 18px; background: url(../../assets/images/bottom-jt.png) no-repeat; margin-left: -12px;}
.marT50{ margin-top: 50px;}
.marB28{ margin-bottom: 28px;}
.padB70{ padding-bottom: 70px;}
.listshow .juryitem{ width: 183px; float: left;}


.playerBg{ position: fixed; width: 100%; height: 100%; background: rgba(0, 0, 0, .5); left:0; top:0; z-index: 10;}
.playerBox{ position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);}

.popbg{ background: rgba(0, 0, 0, 0.8); left: 0; top:0; bottom: 0; right: 0; z-index: 10; position:fixed;}
.videobox{ width:1123px; height: 570px; position:fixed; top:50%; left: 50%; transform: translate(-50%, -50%);}
.videobox video{ width: 100%; height: 100%;}
.video-close{ position: absolute; top:0; right: 0; width: 24px; height: 24px; background: url(../../assets/images/video-close.png) no-repeat; cursor: pointer; z-index: 5;}