.main{width:1200px; margin: 0 auto;}
.bg1{background: url(../../assets/images/ghbg1.png) no-repeat center top;}
.bg2{background: url(../../assets/images/ghbg2.png) no-repeat center top;}
.bg3{background: url(../../assets/images/ghbg3.png) no-repeat center top;}
.banner{vertical-align:top; height: 340px; background: url(../../assets/images/banner-planning.png) no-repeat center top;}
.banner img{ width: 100%;}
.ghbg{ background: #F0F0F0;}
.ghbg1{ height: 111px; background: url(../../assets/images/gh_02.jpg) no-repeat center top;}
.ghbg2{ height: 111px; background: url(../../assets/images/gh_03.jpg) no-repeat center top;}
.ghbg3{ height: 111px; background: url(../../assets/images/gh_04.jpg) no-repeat center top;}
.ghbg4{ height: 110px; background: url(../../assets/images/gh_05.jpg) no-repeat center top;}
.ghbg5{ height: 111px; background: url(../../assets/images/gh_06.jpg) no-repeat center top;}
.ghbg6{ height: 111px; background: url(../../assets/images/gh_07.jpg) no-repeat center top;}
.ghbg7{ height: 127px; background: url(../../assets/images/gh_08.jpg) no-repeat center top;}
.ghbg8{ height: 127px; background: url(../../assets/images/gh_09.jpg) no-repeat center top;}
.ghbg9{ height: 127px; background: url(../../assets/images/gh_10.jpg) no-repeat center top;}
.ghbg10{ height: 127px; background: url(../../assets/images/gh_11.jpg) no-repeat center top;}
.ghbg11{ height: 127px; background: url(../../assets/images/gh_12.jpg) no-repeat center top;}
.ghbg12{ height: 127px; background: url(../../assets/images/gh_13.jpg) no-repeat center top;}
.ghbg13{ height: 132px; background: url(../../assets/images/gh_14.jpg) no-repeat center top;}
.ghbg14{ height: 143px; background: url(../../assets/images/gh_15.jpg) no-repeat center top;}
.ghbg15{ height: 137px; background: url(../../assets/images/gh_16.jpg) no-repeat center top;}
.ghbg16{ height: 137px; background: url(../../assets/images/gh_17.jpg) no-repeat center top;}
.ghbg17{ height: 137px; background: url(../../assets/images/gh_18.jpg) no-repeat center top;}
.ghbg18{ height: 123px; background: url(../../assets/images/gh_19.jpg) no-repeat center top;}
.ghbg19{ height: 152px; background: url(../../assets/images/gh_20.jpg) no-repeat center top;}

.cpblock{ width: 1200px; margin:0 auto; overflow: hidden;}
.cpblock .left{ width: 564px; height: 332px; float: left;}
.cpblock .right{ width: 636px; height: 332px; float: left;}
.cpblock .right .tab{ font-size: 16px; margin:23px 0 8px; width: 633px; border-bottom: 1px solid #F0B645; padding-bottom: 4px;color: #333333; overflow: hidden; cursor: pointer;}
.cpblock .tab span{ display: block; line-height: 20px; vertical-align: top; margin-top:10px;}
.cpblock .tab em{ font-size: 12px; line-height: 16px; vertical-align: top;}
.right .tab .item{ width: 211px; height: 55px; text-align: center; float: left;}
.cpblock .tab .on{ background: #F0B645}

.offer :global(.offer-list .info){ color: #333333;}
.offer :global(.swiper-pagination-bullets .swiper-pagination-bullet){ margin:0 10px;}
.offer :global(.swiper-button-prev){position:absolute;left:0;top:50%;margin-top:-63px;width:66px;height:125px;background:url(../../assets/images/arrow-l1.png) no-repeat; border-radius: 0;}
.offer :global(.swiper-button-next){position:absolute;right:0;top:50%;margin-top:-63px;width:66px;height:125px;background:url(../../assets/images/arrow-r1.png) no-repeat; border-radius: 0;}
.offer :global(.swiper-button-prev:hover){background:url(../../assets/images/arrow-l1-on.png) no-repeat;}
.offer :global(.swiper-button-next:hover){background:url(../../assets/images/arrow-r1-on.png) no-repeat;}