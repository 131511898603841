.wrap-bg{ background: #F5F5F5; padding:20px 0;}
.wrap-over{ width:1200px; margin:0 auto; overflow: hidden; position: relative;}
.wrap{ width:1200px; margin:0 auto;}
.wrap::after{clear: both; height:0; content: "."; overflow:hidden; display: block; visibility: hidden;}
.visiting-card{ width:1200px; height: 200px; margin:0 auto 20px; background: url(../../assets/images/card.png) no-repeat center; border-radius: 4px; color:#ffffff; font-size:20px;}
.card{ padding:47px 0 0 40px;}
.card img{ width:105px; height:105px; border-radius: 50%; float: left;}
.card .info{ float: left; margin-left:20px; padding-top:26px;}
.card .info .name{ font-weight: 900; margin-bottom:15px;}
.card .info .sub{ font-size:16px;}
.info .sub span{ margin-right:20px;}

.left-area{ width:200px; float: left; -webkit-box-sizing: border-box; -webkit-moz-sizing: border-box; -webkit-ms-sizing: border-box; box-sizing: border-box; border:1px solid #EDEDED; border-radius:5px; background: #ffffff; overflow: hidden; height: 100%; padding-bottom:9000px; margin-bottom:-9000px;}
.right-area{ width:980px; float: right; -webkit-box-sizing: border-box; -webkit-moz-sizing: border-box; -webkit-ms-sizing: border-box; box-sizing: border-box; height: 100%;}
.button1{ display:inline-block; width:150px; height: 46px; line-height:46px; text-align: center; font-size:16px; background:#F29D4F; border:1px solid #F29D4F; color:#333333; border-radius: 23px;}
.button2{ display:inline-block; width:107px; height: 34px; line-height:34px; text-align: center; font-size:16px; background:#ffffff; border:1px solid #F29D4F; color:#F29D4F;}
:global(.marT20){ margin-top:20px;}
:global(.marB20){ margin-bottom:20px;}
:global(.marL20){ margin-left:20px;}
:global(.marR20){ margin-right:20px;}
.block{ background:#ffffff;}
.block1::after{ content: "."; display:block; clear: both; visibility: hidden; height:0;}
.radius5{ border-radius: 5px;}

.block1{ padding:32px 40px; border:1px solid #EDEDED; border-radius: 5px; background:#ffffff; position: relative;}
.block1::after{ content: ".";display: block;clear: both;visibility: hidden;line-height: 0;height: 0;}
.block1 .name{ float: left; color:#4C4C4C;}
.block1 .name .title{ font-size: 18px; font-weight: bold; line-height: 36px;}
.block1 .name span{ font-size: 16px; margin-top:10px; display: block;}
.block1 .button1{ float: right; margin-top:10px;}
.block1 .button2{ float: right; cursor: pointer;}
.block1 .quantity{ color:#666666; width:100px; font-size:26px; float: left; font-weight: bold; line-height: 36px; text-align: center; margin-left:300px;}
.title-block{ background: #ffffff; border:1px solid #EDEDED; height: 76px; border-radius: 5px 5px 0 0; font-size:18px; color:#B4B4B4; font-weight: bold; padding-left:6px;}
.title-block span{ padding-top:40px; display: inline-block; margin:0 34px; cursor:pointer;}
.title-block .on{ color:#F29D4F; border-bottom:3px solid #F29D4F; padding-bottom:12px;}
.title-block .name{ color: #333333;}
.block1 .pop{ position: absolute; right: 150px; top:33px; background: url(../../assets/images/pop.png) no-repeat; width:445px; height: 130px; z-index: 10;}
.block1 .pop .title{ font-size: 22px; margin-right: 23px; text-align: center; color: #ffffff; margin:35px 0 10px;}
.block1 .pop .tel{ font-size: 20px; margin-right: 23px; text-align: center; color: #ffffff;}

/* 我的主页 列表 */
.per-list li{ height:158px; border:1px solid #EDEDED; position: relative; margin-top:10px; background: #ffffff;}
.per-list .pic{ width:208px; height:158px; float: left;}
.per-list .intr{ width:490px; float:left; color:#4C4C4C; font-size:14px; margin-left:20px; padding-top:15px;}
.per-list .intr .name{ font-size:18px; line-height: 26px; height: 52px; display: -webkit-box; -webkit-box-orient:vertical; -webkit-line-clamp: 2; overflow: hidden;}
.per-list .intr .info{ margin-top:15px;}
.per-list li .button2{ float: right; margin:110px 40px 0 0;}
.per-list li .icon1{ position: absolute; right:0; top:0; width:86px; height:87px; background:url(../../assets/images/icon.png) no-repeat; background-position:-114px 0;}
.per-list li .icon2{ position: absolute; right:0; top:0; width:86px; height:87px; background:url(../../assets/images/icon.png) no-repeat; background-position:-114px -87px;}

.per-list2{ border-left:1px solid #EDEDED; border-right:1px solid #EDEDED; border-radius: 0 0 5px 5px; overflow: hidden;}
.per-list2 li{ border-bottom:1px solid #EDEDED; overflow: hidden; padding:30px 40px; font-size:14px; color:#333333;}
.per-list2 li:nth-last-of-type(1){ border-radius: 0 0 5px 5px;}
.per-list2 li .pic{ width:80px; height: 80px; float: left; border-radius:50%;}
.per-list2 .info{ margin-left:100px;}
.per-list2 .info .top{ height: 80px; line-height: 80px; font-size: 16px;}
.info .top .name{ float: left;}
.info .top .time{ float: right; color: #666666;}
.info .intr{ line-height: 25px;}

.per-clsslist{ padding:20px 40px; background: #ffffff;}
.per-clsslist li{ border: none; border-bottom: 1px solid #F0F0F0; height: 133px; margin-top:0; padding:18px 0; box-sizing: border-box;}
.per-clsslist li:nth-last-child(1){ border-bottom: none;}
.per-clsslist li .pic{ width: 142px; height: 96px; position: relative;}
.per-clsslist li .pic span{ position: absolute; left: 0; top:16px; width: 76px; height: 32px; font-size: 16px; color: #333333; line-height: 32px; text-align: center; }
.per-clsslist li .intr{ width: 515px; font-size: 14px; color: #666666; margin-left: 15px; padding-top: 0;}
.per-clsslist li .intr .name{ font-size: 16px; font-weight: bold; height: 20px; line-height: 20px; padding-bottom: 18px;}
.per-clsslist li .intr .info{ margin-top: 0;}
.per-clsslist li .btns{ float: right; width: 100px; height: 95px; padding-left: 30px; border-left: 1px solid #F29D4F; display: flex; flex-direction: column; justify-content: center;}
.per-clsslist li .btns .button1{ display: inline-block; width: 100px; height: 36px; line-height: 34px; font-size: 16px; color: #ffffff; border-radius: 5px; background: #6CC8CC; border:1px solid #6CC8CC; box-sizing: border-box; margin:10px 0; cursor:pointer;}
.per-clsslist li .btns .button2{ display: inline-block; width: 100px; height: 36px; line-height: 34px; font-size: 16px; color: #6CC8CC; border-radius: 5px; background: #ffffff; border:1px solid #6CC8CC; box-sizing: border-box; margin:10px 0; cursor:pointer;}
.per-clsslist li .btns .button3{ display: inline-block; width: 100px; height: 36px; line-height: 34px; font-size: 16px; color: #ffffff; border-radius: 5px; background: #EDC57D; border:1px solid #EDC57D; box-sizing: border-box; margin:0; cursor:pointer;}
.per-clsslist li .btns .button4{ display: inline-block; width: 100px; height: 36px; line-height: 34px; font-size: 16px; color: #ffffff; border-radius: 5px; background: #6DAFE1; border:1px solid #6DAFE1; box-sizing: border-box; margin:0; cursor:pointer;}
.per-clsslist li .btns .button5{ display: inline-block; width: 100px; height: 36px; line-height: 34px; font-size: 16px; color: #ffffff; border-radius: 5px; background: #E18680; border:1px solid #E18680; box-sizing: border-box; margin:0; cursor:pointer;}

.classtitle{ font-size: 20px; color: #666666; border-left: 6px solid #F29D4F; padding-left: 30px; height: 18px; line-height: 18px; margin-bottom: 20px;}

.popbg{ position: fixed; width: 100%; height: 100%; background: rgba(0, 0, 0, .6); left: 0; top:0;}
.popbg .pop{ width: 550px; padding:30px 0; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); background: #ffffff;}
.popbg .pop .yd{ font-size: 12px; color: #333333; border-bottom: 1px solid #6CC8CC; padding:0 70px 20px;}
.popbg .pop .close{ position: absolute; top:16px; right: 16px; width: 32px;height: 32px; background: url(../../assets/images/close3.png) no-repeat; cursor: pointer;}
.popbg .pop .tabtitle{ font-size: 26px; color: #999999; text-align: center; padding-bottom: 20px;}
.popbg .pop .tabtitle span{ margin:0 25px; cursor: pointer;}
.popbg .pop .tabtitle .on{ color: #6CC8CC;}
.popbg .pop .content{ padding: 0 65px;}
.popbg .pop .item{ padding-top:20px; font-size: 14px; color: #999999; line-height: 25px; overflow: hidden;}
.popbg .pop .item .name{ font-size: 16px; color: #333333; float: left; width: 80px; text-align: right;}
.popbg .pop .item .tit{ color: #333333; float: left; width: 340px;}
.popbg .pop .item .info{ float: left; width: 340px;}
.btnsub{ width: 134px; height: 36px; line-height: 36px; text-align: center; font-size: 16px; color: #ffffff; margin:20px auto 0; background:#6CC8CC; border-radius: 5px; cursor: pointer;}

/* .permy-list{ padding:0 40px 0 30px; background: #ffffff;}
.permy-list li{ padding:23px 0; overflow: hidden; border-bottom: 1px solid #F0F0F0;}
.permy-list .left{ font-size: 14px;color: #666666; float: left; line-height: 25px;}
.itemline{ font-size: 16px; color: #333333;}
.itemline span{ display: inline-block; width: 310px; height: 30px; line-height: 30px; font-weight: bold; margin-right: 90px;}
.permy-list .work{ width: 750px;}
.permy-list .jd{ margin-right: 50px;}
.permy-list .linestep{width: 155px; height: 4px; border-radius: 2px; background: #E3EDED; display: inline-block;; margin-left: 15px; vertical-align: top; margin-top:10px; position: relative;}
.permy-list .linestep em{ display: inline-block; height: 4px; border-radius: 2px; background: #6CC8CC; width: 130px; position: absolute; left: 0; top:0;}
.permy-list .right{ float: right;}
.permy-list .right span{ display: inline-block; width: 100px; height: 36px; line-height: 36px; text-align: center; color: #ffffff; border-radius: 3px; margin-top:30px;}
.permy-list .right .btn1{ background: #6CC8CC;}
.permy-list .right .btn2{ background: #6DAFE1;}
.permy-list .right .btn3{ background: #EDC57D;} */

/* 基本资料 */
.basic-data{ margin-top:10px; border:1px solid #EDEDED; border-radius: 0 0 5px 5px; padding:40px 40px 25px; background:#ffffff; overflow: hidden;}
.portrait{ padding-bottom: 25px; overflow: hidden;}
.portrait img{ width:138px; height: 138px; border:2px solid #DDDDDD; float: left;}
.portrait .button2{ margin:105px 0 0 20px;}
.data-item{ height: 40px; line-height: 40px; font-size: 16px; color: #333333; margin-bottom: 32px;}
.data-item .name{ display:inline-block; width:88px; float: left;}
.data-item .input{ height: 38px; width: 418px; line-height: 38px; font-size: 16px; color: #666666; padding-left:10px; box-sizing:border-box; border:1px solid #CCCCCC;}
.gender .sex{ float: left; margin-right:60px; position: relative; background: url(../../assets/images/choose.png) no-repeat left 10px;}
.gender .on{ background: url(../../assets/images/choose-on.png) no-repeat left 10px;}
.gender .sex input{ width:20px; height: 20px; vertical-align: top; margin:10px 5px 0 0;}
.data-item .place{ float: left; margin-left:-55px;}
.place .input{ width:180px; margin-left:55px; background:url(../../assets/images/arrow.png) no-repeat 160px center; padding-right:15px;}
.tijiao{ padding:10px 0 0 88px;}

.playerBg{ position: fixed; width: 100%; height: 100%; background: rgba(0, 0, 0, .5); left:0; top:0; z-index: 10;}
.playerBox{ position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);}
.playerBox .close{ position: absolute; top:16px; right: 16px; width: 32px;height: 32px; background: url(../../assets/images/close3.png) no-repeat; cursor: pointer; z-index: 10;}