.banner{position: relative;}
.banner img{ width: 100%;}
.banner{}
.banner1{vertical-align:top; height: 340px; background: url(../../assets/images/banner-activity1.png) no-repeat center top;}
.banner2{vertical-align:top; height: 340px; background: url(../../assets/images/banner-activity2.png) no-repeat center top;}
.bgf6{ background: #F6F6F6; min-height: 100%;}
.bgff{ background: #ffffff;}
.pad33{ padding:0 3.33%;}
.content{ overflow: hidden;}
.title-search{ padding-bottom:30px;}
.zx-title{ font-size:24px; color: #333333; line-height: 60px; padding:10px 40px 0px;}
.zx-title span{ margin-right:59px; padding-bottom:5px; cursor: pointer;}
.zx-title .on{ color: #FF4F5F; border-bottom:3px solid #FF4F5F;}
.zx-title-sm{ font-size:14px; color: #333333; line-height: 60px; padding:10px 40px 0px;}
.zx-title-sm span{ font-size:14px; margin-right:30px; padding-bottom:5px; cursor: pointer;}
.zx-title-sm span:hover{ color: #FF4F5F;}
.zx-title-sm .on{ color: #FF4F5F; border-bottom:3px solid #FF4F5F;}
.search{ border:2px solid #FF4F5F; width:236px; height: 36px; float:right; border-radius:18px; margin-top:-30px;}
.search input{ float: left; width:210px; height: 36px; line-height: 36px; padding-left:10px; box-sizing:border-box; font-size:16px; color: #C6C6C6;}
.search button{ border:none; width:21px; height: 21px; margin-top:8px;}
.classify{ line-height: 40px; color: #333333; margin-left:-10px; padding-top:10px;}
.classify a{ font-size:20px; color: #333333; vertical-align:top; padding:0 10px;}
.classify .on{ color:#FF4F5F;}
.forsearch{ overflow: hidden;}
.forsearch .search{ margin-top:0;}

.category{ text-align: center;}
.cate-item{ display: inline-block; width: 350px; height: 100px; line-height: 100px; border:1px solid #F0B645; box-sizing: border-box; text-align: center; font-size: 20px; color: #231815; margin:30px 10px; cursor:pointer;}
.cate-item .line{ display: inline-block; width: 4px; height: 34px; background: #F0B645; margin:35px 30px 0 20px; vertical-align: top;}
.cate-item.on{ border:1px solid #F0B645; background: #F0B645;}
.cate-item.on .line{ background: #ffffff;}
.cate-item.on .icon1{background: url(../../assets/images/otherIcon2.png) no-repeat center; background-size: contain;}
.cate-item.on .icon4{background: url(../../assets/images/otherIcon3.png) no-repeat center; background-size: contain;}
.cate-item i{ display: inline-block; width:43px; height: 34px; vertical-align: top; margin:33px auto 15px;}
.cate-item .icon1{ background: url(../../assets/images/otherIcon2-on.png) no-repeat center; background-size: contain;}
.cate-item .icon4{ background: url(../../assets/images/otherIcon3-on.png) no-repeat center; background-size: contain;}

.list-wrap{ display:none;}
.list-wrap:nth-of-type(1){ display:block;}
.list{ padding:0 40px;}
.list li{ padding:25px 0 45px; overflow:hidden; border-bottom:1px solid #E2E2E2;}
.list li a{color: #333333;}
.list li .left{ float: left; margin-right:25px; width:270px; height: 168px; overflow: hidden;}
.list li .right{ float: left; width: 825px; height: 168px; position: relative;}
.list li .left img{ width:100%; height: 100%; cursor: pointer;}
.list .right .title{ font-size:20px; color: #333333; line-height: 40px; height: 40px; overflow: hidden; cursor: pointer; font-weight: bold;}
.list .right .source{ font-size:16px; color:#C6C6C6; margin-top:30px;}
.list .right .info{ font-size:18px; color:#666666; line-height: 35px;}
.list .right .ongoing{ display: inline-block; color: #ffffff; width:84px; height: 26px;line-height: 26px; font-size: 14px; background: #E18680; text-align: center; border-radius: 3px;}
.list .right .end{ display: inline-block; color: #ffffff; width:84px; height: 26px;line-height: 26px; font-size: 14px; background: #999999; text-align: center; border-radius: 3px;}
.list .source a{ color: #4F9AFF; padding-right:10px;}
.list .source span{ padding:0 10px;}
.list .right .amount{ position: absolute; right: 50px; bottom:0; font-size: 14px; color: #999999; display: none;}

.page{ height: 160px; display:flex; justify-content:center; align-items:center; clear: both;}
.page-num{ height: 44px; display: inline-block; border-top:1px solid #E4E2E2; border-bottom:1px solid #E4E2E2; border-left:1px solid #E4E2E2;}
.page-num li{ display: inline-block; width:33px; height: 44px; line-height: 44px; text-align: center; font-size:14px; color:#333333; border-right:1px solid #E4E2E2; float: left; cursor:pointer;}
.page-num span{ display: inline-block; line-height: 44px; text-align: center; font-size:14px; color:#333333; border-right:1px solid #E4E2E2; float: left; cursor:pointer;}
.page-num ul{ float: left;}
.page-num ul .on{ background: #ff4f5f; color:#ffffff;}
.page-num .pn-prev{ width:88px; height: 44px; background:url(../../assets/images/prev-page.png) no-repeat 10px center;}
.page-num .pn-next{ width:88px; height: 44px; background:url(../../assets/images/next-page.png) no-repeat 72px center;}
span.pn-prev.on{ color: #999999; background:url(../../assets/images/prev-on.png) no-repeat 10px center;}
span.pn-next.on{ color: #999999; background:url(../../assets/images/next-on.png) no-repeat 72px center;}
li.pn-prev.on{ color: #ffffff; background:url(../../assets/images/prev-page-on.png) no-repeat 10px center #FF4F5F;}
li.pn-next.on{ color: #ffffff; background:url(../../assets/images/next-page-on.png) no-repeat 72px center #FF4F5F;}
.page-num li img{ margin:17px 5px 0;}
