.bgff{background: #F0F0F0;}
.banner{ background: url(../../assets/images/banner-major.png) no-repeat center top; height: 340px;}
.classify{ background: #ffffff; height: 116px; line-height: 116px;}
.classlist{ margin:0 -30px; text-align: center;}
.classify .item{ font-size: 16px; color: #333333; display: inline-block; height: 86px;line-height: 30px; vertical-align: top; padding:30px 30px 0; text-align: center;}
.classify .item p{ font-size: 20px;}
.classify .item:hover{ font-size: 20px; color:#ECAB2F;}
.classify .item:hover p{ font-size: 24px;}

.part1{ background: url(../../assets/images/major-bg1.png) center top; background-size: cover; padding-bottom: 50px;}
.threelist{ margin-left: -24px; overflow: hidden;}
.threelist .item{ width: 385px; float: left; margin-left: 23px; font-size: 18px; color: #ffffff;}
.threelist .item .pic{ width: 385px;height: 216px;}
.threelist .item .pic img{ width: 100%; height: 100%;}
.threelist .item .name{ line-height: 22px; padding-top: 20px;}
.threelist .item .name strong{ margin-right: 30px;}

.fivelist{ margin-left: -26px; overflow: hidden;}
.fivelist .item{ width: 223px; float: left; margin-left: 22px;}
.fivelist .item .pic{ width: 223px; height: 217px;}
.fivelist .item .pic img{ width: 100%; height: 100%;}
.fivelist .item .name{ font-size: 18px; color: #333333; line-height: 30px; padding-top:20px;}
.fivelist .item .name p{ font-weight: bold;}
.padB50{ padding-bottom: 50px;}

.part2{ background: #333333; overflow: hidden; padding-bottom: 50px;}
.fourlist{ margin-left: -24px; overflow: hidden;}
.fourlist .item{ width: 284px; float: left; margin-left: 22px;}
.fourlist .item .pic{ width: 284px; height: 217px;}
.fourlist .item .pic img{ width: 100%; height: 100%;}
.fourlist .item .name{ font-size: 18px; color: #ffffff; line-height: 30px; padding-top:20px;}
.fourlist .item .name strong{ margin-right: 30px;}
.fourlist .item .name p{ font-weight: bold;}
.fourlist.col333 .name{ color: #333333;}

.part3{ background: url(../../assets/images/major-bg2.png) no-repeat center top; padding-bottom: 20px;}
.part3 .item{ padding-bottom: 30px;}

.ouractivity{ background:#333333; background-size: cover; overflow: hidden; padding-bottom: 50px;}
.ouractivity .list{ margin-left: -39px;}
.ouractivity .item{ margin-left: 39px; width: 560px; float: left;}
.ouractivity .item .btn{ width: 560px; height: 84px; box-sizing: border-box; border:1px solid #ffffff; line-height: 82px; text-align: center; font-size: 30px; color: #ffffff; margin-top: 24px;}
.ouractivity .item:hover .btn{ background: #F0B645; font-weight: bold; border:1px solid #F0B645; color: #333333;}