body{background:#F0F0F0;}.wrap{width:1200px;margin:0 auto}
.bg2e{ background: #2e2e2e; padding-bottom: 50px;}
.banner{vertical-align:top; height: 340px; cursor: pointer;}
:global(.module-title){padding:45px 0 40px;font-size:26px;color:#fff;text-align:center;position:relative;line-height:26px}
:global(.module-title .col333){ color: #333333;}
:global(.module-title .subhead){font-size:20px;color:#f0b645;line-height:20px;padding-top:20px}
:global(.module-title .button2){position:absolute;top:50%;right:0;margin-top:-25px}
.advantage{margin-left:-60px;font-size:12px;color:#333;overflow:hidden}
.advantage i{display:block;width:50px;height:50px;margin:25px auto 0}
.advantage .icon1{background:url(../../assets/images/icon-adv1.png) no-repeat}
.advantage .icon2{background:url(../../assets/images/icon-adv2.png) no-repeat}
.advantage .icon3{background:url(../../assets/images/icon-adv3.png) no-repeat}
.advantage .icon4{background:url(../../assets/images/icon-adv4.png) no-repeat}
.advantage li:hover .icon1{background:url(../../assets/images/icon-adv1-on.png) no-repeat}
.advantage li:hover .icon2{background:url(../../assets/images/icon-adv2-on.png) no-repeat}
.advantage li:hover .icon3{background:url(../../assets/images/icon-adv3-on.png) no-repeat}
.advantage li:hover .icon4{background:url(../../assets/images/icon-adv4-on.png) no-repeat}
.advantage li{width:255px;height:271px;float:left;margin-left:60px;background:#fff;text-align:center;line-height:30px}.advantage li span{font-size:14px; font-weight:bold;}.advantage li p{padding:0 45px; text-align: left;}.advantage li:hover{background:#f0b645}
.video-list{overflow:hidden; width: 1200px; margin:0 auto; position: relative; margin-bottom: 50px;}.video-list li{height:350px;float:left}
.video-list .video{height:350px;overflow:hidden;float:left}
.video-list .video img{width:100%; height: 100%;}
.video-list .info{width:203px;height:290px;padding:60px 30px 0;line-height:30px;float:left;background:#fff;font-size:16px;color:#666;text-align:left}
.video-list .info .name span{font-size:20px;color:#333;display:block}
.video-list .info .source{padding-top:30px}
.video-list .info .source span{color:#333}
.video-list .info .play-btn{background:url(../../assets/images/play-btn.png) no-repeat left center;padding-left:60px;height:40px;line-height:40px;color:#333;margin-top:50px}
.video-list .swiper-slide{height:350px;float:left}

.videos{ margin: -25px 0 0 -25px;}
.videos .nr{ width: 284px; height: 159px; margin: 25px 0 0 26px; float: left; position: relative;}
.videos .nr:first-of-type{width: 578px;height: 342px;}
.videos .nr:first-of-type .bz{height: 62px; line-height: 62px; font-size: 24px;}
.videos .nr:first-of-type .bz .playbtn{ width:68px; height: 68px; bottom: 28px; right: 55px;}
.videos .nr:first-of-type .bz span{ width: 400px;}
.videos .nr .pic{ width: 100%; height: 100%;}
.videos .nr img{ width: 100%; height: 100%; cursor: pointer;}
.videos .nr .bz{ height: 30px; line-height: 30px; font-size: 14px; color: #ffffff; text-indent: 1em; position: absolute; left:0; bottom: 0; background: rgba(0, 0, 0, .8); width: 100%;}
.videos .nr .bz span{ white-space:nowrap; text-overflow:ellipsis; display: block; width: 200px; overflow: hidden;}
.videos .nr .bz .playbtn{ width: 33px; height: 33px; background: url(../../assets/images/play-btn3.png) no-repeat; background-size: contain; position: absolute; bottom: 15px; right: 26px; cursor:pointer;}

.director{padding-bottom:55px;background:url(../../assets/images/zjbg.png) no-repeat center top}
.director .personage{width:1184px;height:315px;background:url(../../assets/images/jy.png) no-repeat;margin-left:16px}
.director .personage .head{width:220px;height:243px;margin:72px 82px 0 103px;float:left}
.director .personage .per-info{font-size:14px;color:#666;float:left}
.director .personage .per-info .title{height:20px;color:#333;line-height:20px;font-size:20px;margin:35px 0 20px;}.director .personage .per-info .title span{margin-right:10px; font-weight: bold;}.director .personage .per-info .title img{margin-right:40px}.director .personage .per-info p{width:700px;line-height:40px}.ramous-teacher{overflow:hidden; background: #2e2e2e; padding-bottom: 50px;}.mt-center strong{margin:0 90px; cursor: pointer;}.mt-center .on{color:#f0b645}
:global(.list-four){margin:-25px 0 0 -26px;padding-top:15px;position:relative;overflow:hidden}
:global(.list-four .left){position:absolute;left:26px;top:50%;margin-top:-83px;width:66px;height:125px;background:url(../../assets/images/arrow-l.png) no-repeat;z-index:10}
:global(.list-four .right){position:absolute;right:2px;top:50%;margin-top:-83px;width:66px;height:125px;background:url(../../assets/images/arrow-r.png) no-repeat;z-index:10}
:global(.list-four .num){height:20px;padding:55px 0 25px;overflow:hidden;clear:both;text-align:center}
:global(.list-four .num span){display:inline-block;width:20px;height:20px;background:url(../../assets/images/icon.png) no-repeat;background-position:0 -180px;margin:0 15px}:global(.list-four .num .on){background-position:-21px -180px}
:global(.list-four li){width:280px;float:left;margin:25px 0 0 26px;position:relative}
:global(.list-four li .pic){width:100%;position:relative}
:global(.list-four li .pic a){display:block;width:100%;height:223px;position:absolute;left:0;top:0;display:none;background:rgba(0,0,0,0.85);text-align:center}
:global(.list-four li .pic a img){width:100%;height:107px;margin-top:58px}
:global(.list-four li .pic:hover a){display:block}
:global(.list-four li img){width:179px;height:179px}
:global(.list-four li .info){font-size:16px;color:#333;text-align:center;line-height:28px;padding:10px 0 15px;display:block}
:global(.list-four li .info p){height:28px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}
:global(.list-four .info-more){display:none;font-size:12px;color:#333;padding:0 15px 20px; text-align: center;}
:global(.list-four .info-more .tLeft){ text-align: left}
:global(.list-four.list1 li){height:370px}
:global(.list-four.list1 li:hover .info){transition:.5s;background:#f0b645;height:217px}
:global(.list-four.list1 li:hover .info i){background:url(../../assets/images/marker-on.png) no-repeat}
:global(.list-four.list1 li:hover .info .from){display:none}
:global(.list-four.list1 li:hover .info .more){display:block}
:global(.list-four.list1 li img){width:100%;height:285px}
:global(.list-four.list1 .info){width:246px;height:120px;position:absolute;left:18px;bottom:0;background:#fff;line-height:35px;transition:.5s}
:global(.list-four.list1 .info i){width:29px;height:51px;position:absolute;left:15px;top:-7px;background:url(../../assets/images/marker.png) no-repeat}
:global(.list-four.list1 .info .from){display:block}
:global(.list-four.list1 .info .more){display:none;padding:10px 27px 0;font-size:12px;line-height:25px; text-align: left;}
:global(.list-four.list2 .pic){text-align:center;padding:25px 0 15px}
:global(.list-four.list2 li){background:#fff;height:360px}
:global(.list-four.list2 li img){border-radius:50%}
:global(.list-four.list2 li:hover){background:#f0b645}
.overseas-teachers{background:url(../../assets/images/overseas.png) no-repeat center top}
:global(.list-five){margin:-20px 0 0 -22px;padding-top:25px;position:relative;overflow:hidden}
:global(.list-five .left){position:absolute;left:22px;top:50%;margin-top:-83px;width:66px;height:125px;background:url(../../assets/images/arrow-l.png) no-repeat}
:global(.list-five .right){position:absolute;right:2px;top:50%;margin-top:-83px;width:66px;height:125px;background:url(../../assets/images/arrow-r.png) no-repeat}
:global(.list-five .num){height:20px;padding:55px 0 25px;overflow:hidden;clear:both;text-align:center}
:global(.list-five .num span){display:inline-block;width:20px;height:20px;background:url(../../assets/images/icon.png) no-repeat;background-position:0 -180px;margin:0 15px}
:global(.list-five .num .on){background-position:-21px -180px}
:global(.list-five li){width:222px;float:left;margin:20px 0 0 22px}
:global(.list-five li .pic){width:100%;height:222px;position:relative}
:global(.list-five li .pic a){display:block;width:222px;height:222px;position:absolute;left:0;top:0;display:none;background:rgba(0,0,0,0.85);text-align:center}
:global(.list-five li .pic a img){width:107px;height:107px;margin-top:58px}
:global(.list-five li .pic:hover a){display:block}
:global(.list-five li img){width:100%;height:222px}
:global(.list-five li .info){background:#fff;font-size:16px;color:#333;text-align:center;line-height:28px;padding:10px 0 15px}
:global(.list-five li .info p){height:28px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}
:global(.list-five.list2){ margin:0; padding-top:10px;}
:global(.list-five.list2 li .info){ margin:0; padding-top:0px; text-align: left; padding-left:25px;}
:global(.list-five.list2 li .info .border-bot){ display: inline-block; border-bottom: 2px solid #F0B645; padding-bottom: 5px;}
:global(.list-five.list2 li:hover .info){ background: #F0B645;}
:global(.list-five.list2 li:hover .info  .border-bot){  border-bottom: 2px solid #ffffff;}
:global(.col666){ color: #666666;}
:global(.bold){ font-weight: bold;}
:global(.size14){ font-size: 16px;}
.overseas-teachers .list-five .info{background:none;color:#fff;text-align:left}
.overseas-teachers .list-five li{margin:0 0 0 22px}
.overseas-teachers .list-five li:hover .info{color:#f0b645}
.overseas-teachers .list-five .num{padding:35px 0 35px}
.plan-list{margin-left:-23px;overflow:hidden}.plan-list li{width:383px;height:258px;border:1px solid #fff;float:left;margin-left:22px;font-size:16px;color:#333;background:#fff;position:relative}.plan-list li .name{font-size:20px;line-height:34px;padding-left:45px;margin-top:43px}.plan-list li .line{display:inline-block;width:7px;height:34px;background:#ff3a00;vertical-align:top;margin-right:22px}.plan-list li .info{padding-left:45px;line-height:30px;margin-top:80px}.plan-list li img{position:absolute;right:0;top:0}.plan-list li .show-pic{transition:.5s}.plan-list li:hover .show-pic{transform:scale(1.2);transition:.5s}.plan-list li:hover .name{color:#ff3a00}.plan-list li:hover .line{background:#ff3a00}.plan-list .plan1{width:586px}.plan-list .plan1 .show-pic{position:absolute;right:0;top:0;width:204px;height:258px;background:url(../../assets/images/plan1.png) no-repeat right center}.plan-list .plan2{width:586px}.plan-list .plan2 .line{background:#001eff}.plan-list .plan2 .show-pic{position:absolute;right:20px;top:0;width:297px;height:258px;background:url(../../assets/images/plan2.png) no-repeat right center}
:global(.list-four.list3 li){height:270px;position:relative}
:global(.list-four.list3 li) .vid{width:100%;height:100%; cursor:pointer;}
:global(.list-four.list3 li) .tit{position:absolute;left:0;bottom:0;width:100%;padding:0 25px;box-sizing:border-box;font-size:14px;color:#fff;height:62px;line-height:62px;background:rgba(0,0,0,0.8)}
:global(.list-four.list3 li) .tit span{float:left;overflow:hidden; width:170px;text-overflow:ellipsis;white-space:nowrap}
:global(.list-four.list3 li) .tit .btn{width:33px;height:33px;float:right;margin-top:12px; cursor:pointer;}
:global(.list-four.list4 .pic){text-align:center; height:222px;}
:global(.list-four.list4 li){background:#fff; margin:0;}
:global(.list-four.list4 li .info){ position: relative; padding-top:50px;}
:global(.list-four.list4 li .info .logo){ position: absolute; left:50%; top:0; margin:-43px 0 0 -43px; border-radius: 50%; width: 86px; height: 86px;}
:global(.list-four.list4 li img){ width:100%; height: 222px;}
:global(.list-four.list4 li .line){ display: inline-block; width: 43px; height: 4px; background: #f0b645; vertical-align: top; margin-top:10px;}
:global(.list-four.list4 li:hover){background:#f0b645}
:global(.list-four.list4 li:hover .line){background:#ffffff}
:global(.list4){ margin:0; padding-top:30px;}

:global(.swiper-container5 .swiper-button-prev){position:absolute;left:0;top:50%;margin-top:-63px;width:66px;height:125px;background:url(../../assets/images/arrow-l.png) no-repeat; border-radius: 0;}
:global(.swiper-container5 .swiper-button-next){position:absolute;right:0;top:50%;margin-top:-63px;width:66px;height:125px;background:url(../../assets/images/arrow-r.png) no-repeat; border-radius: 0;}
:global(.swiper-container5 .swiper-pagination){ position:static; bottom:0; margin-top:30px;}
:global(.swiper-container5 .swiper-pagination-bullet){display:inline-block;width:20px;height:20px;background:url(../../assets/images/icon.png) no-repeat;background-position:0 -180px;margin-left:25px; opacity: 1;}
:global(.swiper-container5 .swiper-pagination-bullet-active){background-position:-21px -180px; border:none;}

:global(.list1 .swiper-slide){margin:25px 0 0 0px;}
:global(.list1.marL0){ margin-left:0; margin-top:0;}
:global(.list2 .swiper-slide){margin:25px 0 0 0px;}
:global(.list2.marRes){ margin-left:0; margin-top:-40px;}
:global(.list2 .swiper-slide){margin:25px 0 0 0px;}
:global(.swiper-container5 li){ margin-left:0;}
:global(.list-five .swiper-container5 .pic){ width:100%;}
:global(.list-five .swiper-container5 .pic img){ width:100%;}
:global(.list-five .swiper-container5 .info){ background: none; color: #ffffff; text-align: left;}
:global(.list-five .swiper-container5 li:hover .info){ color: #F0B645;}
:global(.hei0){ height: 0; padding-top:0;}
:global(.swiper-container-s2 .pic){ cursor: pointer;}

.popbg{ background: rgba(0, 0, 0, 0.8); left: 0; top:0; bottom: 0; right: 0; z-index: 10; position:fixed;}
.videobox{ width:1123px; height: 570px; position:fixed; top:50%; left: 50%; transform: translate(-50%, -50%);}
.videobox video{ width: 100%; height: 100%;}
.video-close{ position: absolute; top:0; right: 0; width: 24px; height: 24px; background: url(../../assets/images/video-close.png) no-repeat; cursor: pointer; z-index: 5;}

:global(.teacherInfo){ width: 857px; box-sizing: border-box; padding:70px 20px 20px 60px; background: #ffffff; position:fixed; top:50%; left: 50%; transform: translate(-50%, -50%);}
:global(.pop-close){ position: absolute; top:20px; right: 20px; width: 32px; height: 32px; background: url(../../assets/images/close4.png) no-repeat; background-size: contain; cursor: pointer; z-index: 5;}
:global(.teacherInfo .left){ width: 180px; float: left;}
:global(.teacherInfo .left img){ width: 180px; height: 185px;}
:global(.left .teainfo){ font-size: 14px; color: #666666; background: #f0b645; text-align: center; padding:10px 0;}
:global(.left .teainfo p){ color: #333333; line-height: 25px; height: 25px; overflow: hidden;}
:global(.left .teainfo p:nth-of-type(1)){ font-size: 16px; font-weight: bold;}
:global(.teacherInfo .right){ margin-left: 220px;}
:global(.teacherInfo .jjname){ font-size: 16px; color: #333333; font-weight: bold;}
:global(.teacherInfo .jjcon){ color: #333333; padding:10px 20px 0 0; font-size: 14px; text-align: justify; line-height: 25px; height: 270px; overflow-y: auto;}
:global(.teacherInfo .tBtns){ clear: both; height: 34px; padding-top: 20px; font-size: 16px; line-height: 30px;  text-align: right;}
:global(.teacherInfo .tBtns .btn1){ display: inline-block; width: 114px; height: 34px; box-sizing: border-box; border:2px solid #F0B645; border-radius: 4px; background: #ffffff; color: #F0B645;text-align: center; margin: 0 20px 0 10px; cursor: pointer;}
:global(.teacherInfo .tBtns .btn2){ display: inline-block; width: 114px; height: 34px; box-sizing: border-box; border:2px solid #F0B645; border-radius: 4px; background: #F0B645; color: #333333;text-align: center; margin: 0 20px 0 10px; cursor: pointer;}
:global(.jjcon::-webkit-scrollbar) {/*滚动条整体样式*/
    width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
}
:global(.jjcon::-webkit-scrollbar-thumb) {/*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px #f0b645;
    background: #f0b645;
}
:global(.jjcon::-webkit-scrollbar-track) {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 0;
    background: rgba(0,0,0,0.1);
}

/*# sourceMappingURL=teacher.css.map */