.bgf6{ background: #F6F6F6; min-height: 100%;}
.bgf6 .wraplist{ width: 1200px; margin:0 auto; padding:30px 0 50px; overflow: hidden;}
.wraplist .leftarea{ width:920px; float: left; box-sizing: border-box; background: #ffffff; padding-top:23px;}
.wraplist .rightarea{ width: 250px; float: right;}
.listnav{ font-size: 16px;height: 48px; color: #666666; border-bottom: 1px solid #F0F0F0; margin-bottom: 25px; overflow: hidden; margin-left: 30px; width: 852px;}
.listnav .item{display: inline-block; width: 94px; height: 48px; margin-right: 10px; text-align: center;}
.listnav .item .line{ width: 62px; height: 2px; display: inline-block; vertical-align: top; margin-top:10px;}
.listnav span{ display: block; width: 94px; height: 36px; line-height: 36px; cursor: pointer;}
.listnav .on span{ color: #ffffff; background: #F0B645;}
.listnav .on .line{ background: #F0B645;}

.listnav .topmenu{overflow: hidden; overflow-x: scroll; -webkit-overflow-scrolling: touch; white-space: nowrap;}
.listnav .topmenu::-webkit-scrollbar {
        display: none;
      }

.banner{ width: 852px; height: 248px; margin-left: 30px; overflow: hidden; cursor: pointer;}
.banner img{ width: 100%; height: 100%;}
.banneritem{ position: relative; height: 248px;}
.bannertit{ position: absolute; width: 100%; left:0; bottom:0; height: 50px; line-height: 50px; background: rgba(0, 0, 0, .5); font-size: 20px; color: #ffffff; text-indent: 2em;}
.banner :global(.slick-arrow){ display: none !important;}
.banner :global(.slick-slider){ height: 100%;}
.banner :global(.slick-slider .slick-arrow){ display: none !important;}
.banner :global(.slick-dots){position:absolute;bottom:15px;left:0;width:100%;height:20px;text-align:right;}
.banner :global(.slick-dots li){display:inline-block;width:20px;height:20px;background:url(../../assets/images/icon.png) no-repeat;background-position:0px -180px;margin:0 10px;}
.banner :global(.slick-dots li button){ width:20px; height: 20px; opacity: 0; cursor: pointer;}
.banner :global(.slick-dots .slick-active){background-position:-21px -180px}

.special{ margin-left: 30px;}
.special .top{ overflow: hidden; line-height: 30px; padding:20px 40px 10px 0;}
.special .title{ font-size: 16px; color: #333333; float: left; width: 600px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}
.special .title a{ color: #333333;}
.special .title em{ font-size: 12px;color: #666666; margin-left: 25px;}
.special .type{ width: 66px; height: 20px; text-align: center; line-height: 18px; border:1px solid #ECAB2F; font-size: 12px; color: #ECAB2F; border-radius: 3px; float: right;}
.special .bottom{ padding-bottom: 30px; border-bottom:1px solid #F0F0F0;}
.imgitem{ display: inline-block;  margin-right: 15px; width: 274x; height: 133px; position: relative;}
.imgitem .imgtit{ position: absolute; left: 0; bottom: 0; background: rgba(0, 0, 0, .5); height: 30px; line-height: 30px; font-size: 14px; color: #ffffff; width: 100%; box-sizing: border-box; padding-left: 10px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}

.list-wrap{}
.list li{ padding:35px 0 0 30px; overflow:hidden;}
.borderBot{ border-bottom:1px solid #E2E2E2; overflow: hidden; padding-bottom: 35px;}
.list li:hover{ background: #F9F9F9;}
.list li a{color: #333333;}
.list li .left{ float: left; margin-right:20px; width:214px; height: 133px;}
.list li .right{ float: left; width: 618px; height: 133px; position: relative;}
.list li .left img{ width:100%; cursor: pointer;}
.list .right .title{ font-size:16px; color: #333333; line-height: 20px; height: 20px; margin-bottom: 10px; overflow: hidden; cursor: pointer; font-weight: bold; text-overflow: ellipsis; white-space: nowrap;}
.list .right .source{ font-size:16px; color:#C6C6C6; margin-top:30px;}
.list .right .info{ font-size:14px; color:#666666; line-height: 25px; height: 50px;overflow: hidden;}
.list .right .type{ width: 66px; height: 20px; border:1px solid #ECAB2F; text-align: center; line-height: 20px; color: #ECAB2F; border-radius: 3px; margin:7px 0 3px; font-size: 12px;}
.list .right .time{ font-size: 12px; color: #666666;}
.list .right .ongoing{ display: inline-block; color: #ffffff; width:84px; height: 26px;line-height: 26px; font-size: 14px; background: #E18680; text-align: center; border-radius: 3px;}
.list .right .end{ display: inline-block; color: #ffffff; width:84px; height: 26px;line-height: 26px; font-size: 14px; background: #999999; text-align: center; border-radius: 3px;}
.list .source a{ color: #4F9AFF; padding-right:10px;}
.list .source span{ padding:0 10px;}
.list .right .amount{ position: absolute; right: 0px; bottom:0; background:url(../../assets/images/eye.png) no-repeat left center; padding-left: 20px; line-height: 20px; font-size: 12px; color: #999999; display: none;}

.blankwhite{ background: #ffffff; margin-bottom: 30px; padding:0 0 35px 25px;}
.blankwhite .tit{ padding:30px 0 20px; font-size:14px; color:#E18680; border-bottom: 1px solid #F0F0F0;}
.blankwhite .tit img{ width: 94px; height: 19px; margin-right: 25px;}
.blankwhite .tags{ padding-top:6px;}
.blankwhite .tags a{ display: inline-block; width: 80px; height: 24px; text-align: center; line-height: 24px; font-size: 12px; color: #666666; background: #F9F9F9; margin:14px 14px 0 0; border-radius: 12px;}
.blankwhite .tags a:hover{ color: #333333; background: #fff5f5; font-weight: bold;}
.blankwhite .videos{ margin-top: -15px; padding-bottom: 20px;}
.videos .item{ width: 206px; background: #FAFAFA; font-size: 12px; color: #666666; overflow: hidden;  margin-top: 35px; padding-bottom: 15px;}
.videos .item:hover{box-shadow: 0px 0px 20px 0 #FAFAFA;}
.videos .item .pic{ width: 206px; height: 110px; position: relative;}
.videos .item .pic img{ width: 100%; height: 100%;}
.videos .item .pic .playbtn{ width: 33px; height: 33px; background: url(../../assets/images/play-btn2.png) no-repeat; background-size: contain; position:absolute; bottom:10px; right: 10px; cursor: pointer;}
.videos .item .title{ color: #333333;margin:15px 19px 0;}
.videos .item .line{ padding: 0 19px;}

.page{ height: 80px; display:flex; justify-content:center; align-items:center; clear: both;}
.page-num{ height: 26px; display: inline-block; border-top:1px solid #E4E2E2; border-bottom:1px solid #E4E2E2; border-left:1px solid #E4E2E2;}
.page-num li{ display: inline-block; width:27px; height: 26px; line-height: 26px; text-align: center; font-size:14px; color:#333333; border-right:1px solid #E4E2E2; float: left; cursor:pointer;}
.page-num span{ display: inline-block; line-height: 26px; text-align: center; font-size:12px; color:#333333; border-right:1px solid #E4E2E2; float: left; cursor:pointer;}
.page-num ul{ float: left;}
.page-num ul .on{ background: #ff4f5f; color:#ffffff;}
.page-num .pn-prev{ width:62px; height: 26px; background:url(../../assets/images/prev-page.png) no-repeat 5px center; text-align: left; text-indent: 15px;}
.page-num .pn-next{ width:62px; height: 26px; background:url(../../assets/images/next-page.png) no-repeat 52px center; text-align: left; text-indent: 5px;}
span.pn-prev.on{ color: #999999; background:url(../../assets/images/prev-on.png) no-repeat 10px center;}
span.pn-next.on{ color: #999999; background:url(../../assets/images/next-on.png) no-repeat 72px center;}
li.pn-prev.on{ color: #ffffff; background:url(../../assets/images/prev-page-on.png) no-repeat 10px center #FF4F5F;}
li.pn-next.on{ color: #ffffff; background:url(../../assets/images/next-page-on.png) no-repeat 72px center #FF4F5F;}
.page-num li img{ margin:17px 5px 0;}