.bgf0{ background: #F0F0F0;}
.banner1{ height: 163px; background: url(../../assets/images/yyj_02.jpg) no-repeat center top;}
.banner2{ height: 164px; background: url(../../assets/images/yyj_03.jpg) no-repeat center top;}
.banner3{ height: 163px; background: url(../../assets/images/yyj_04.jpg) no-repeat center top;}
.banner4{ height: 163px; background: url(../../assets/images/yyj_05.jpg) no-repeat center top;}
.banner5{ height: 164px; background: url(../../assets/images/yyj_06.jpg) no-repeat center top;}
.banner6{ height: 163px; background: url(../../assets/images/yyj_07.jpg) no-repeat center top;}

.yyj{ height: 440px; margin-top:10px;}
.yyj1{ background: url(../../assets/images/yyj_15.jpg) no-repeat center top;}
.yyj2{ background: url(../../assets/images/yyj_17.jpg) no-repeat center top;}
.yyj3{ background: url(../../assets/images/yyj_19.jpg) no-repeat center top;}
.yyj4{ background: url(../../assets/images/yyj_21.jpg) no-repeat center top;}
.yyj5{ background: url(../../assets/images/yyj_23.jpg) no-repeat center top;}
.yyj6{ background: url(../../assets/images/yyj_25.jpg) no-repeat center top;}
.yyj7{ background: url(../../assets/images/yyj_27.jpg) no-repeat center top;}
.yyj8{ background: url(../../assets/images/yyj_29.jpg) no-repeat center top;}
.yyj9{ background: url(../../assets/images/yyj_31.jpg) no-repeat center top;}
.yyj10{ background: url(../../assets/images/yyj_33.jpg) no-repeat center top;}