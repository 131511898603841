:global(.offer-list){position:relative; overflow: hidden;}
:global(.offer-list ul){margin:10px 0 0 0; margin-left: -36px;}
:global(.offer-list li){width:272px;margin:0 0 0 36px;float:left}
:global(.offer-list .pic){width:272px;height:272px;position:relative}
:global(.offer-list .pic .c-block){width:257px;height:257px;position:absolute;left:0;bottom:0; box-sizing: border-box;}
:global(.offer-list .pic img){position:absolute;right:0;top:0;width:262px;height:262px; transition: .5s;}
:global(.offer-list .pic img:hover){ transform:scale(1.1);}
:global(.offer-list .info){width:257px;margin-left:15px;font-size:12px;color:#fff;line-height:25px;padding-bottom:20px}
:global(.offer-list .info .name){font-size:16px;height:30px;line-height:30px;padding-top:5px}
:global(.offer-list .info .line){display:block;height:3px;background:#f0b645;margin:6px 0 10px}
:global(.offer-list .info .major){font-size:14px}
:global(.offer-list .num){height:20px;text-align:center;clear:both;padding-bottom:50px}
:global(.offer-list .num span){display:inline-block;width:20px;height:20px;background:url(../../assets/images/icon.png) no-repeat;background-position:0 -180px;margin:0 15px}
:global(.offer-list .num .on){background-position:-21px -180px}
:global(.offer-list .left){position:absolute;left:0;top:50%;margin-top:-103px;width:66px;height:125px;background:url(../../assets/images/arrow-l.png) no-repeat}
:global(.offer-list .right){position:absolute;right:0;top:50%;margin-top:-103px;width:66px;height:125px;background:url(../../assets/images/arrow-r.png) no-repeat}
:global(.offer-list .licol1 .c-block){ border:1px solid #6DAFE1;}
:global(.offer-list .licol2 .c-block){ border:1px solid #ECAB2F;}
:global(.offer-list .licol3 .c-block){ border:1px solid #6CC8CC;}
:global(.offer-list .licol4 .c-block){ border:1px solid #E18680;}
:global(.offer-list .licol1 .line){ background: #6DAFE1;}
:global(.offer-list .licol2 .line){ background: #ECAB2F;}
:global(.offer-list .licol3 .line){ background: #6CC8CC;}
:global(.offer-list .licol4 .line){ background: #E18680;}
:global(.offer-list .licol1 .pic:hover .c-block){ background: #6DAFE1;}
:global(.offer-list .licol2 .pic:hover .c-block){ background: #ECAB2F;}
:global(.offer-list .licol3 .pic:hover .c-block){ background: #6CC8CC;}
:global(.offer-list .licol4 .pic:hover .c-block){ background: #E18680;}
