.header{height:92px;background:#1d1d1d}
.header .logo{width:209px;height:45px;float:left;padding-top:24px}
.nav-login{padding-top:10px;font-size:14px;color:#fff;float:right}
.nav-login .login{height:34px;text-align:right;line-height:34px;font-size:0; color: #FABD8D;}
.nav-login .login .toload{padding-right:50px;font-size:14px}
.nav-login .login .name{display:inline-block;padding-right:10px;font-size:14px;}
.nav-login .login .name a{padding-left:50px}
.nav-login .login .name span{ padding:0 5px; cursor: pointer;}
.nav-login .login img{width:34px;height:34px;vertical-align:top;border-radius: 50%; cursor: pointer;}
.nav-login .nav{padding-top:12px; font-size: 14px;}
.nav-login .nav a{margin-left:30px}
.nav-login .nav .on{color:#f0b645; border-bottom: 2px solid #F0B645; padding-bottom: 14px;}
.nav-login .nav  span{ margin-left: 30px;}
.nav .dropdown-menu{ display: inline-block; position: relative; height: 36px;}
.nav .dropdown-menu .dropdown{ position:absolute; left:-20px; width: 160px; top:36px; border:1px solid #f0f0f0; background: #F0F0F0; z-index: 100;}
.nav .dropdown-menu .dropdown a{ margin:0; line-height: 50px; font-size: 16px; color: #666666;display: block; text-align: center;}
.nav .dropdown-menu .dropdown a:hover{ background: #f0b645; color: #333333; font-weight: bold;}

.to-login{display:inline-block;font-size:14px;padding-left:10px;margin:0 -20px 0 -30px}
.to-login span{padding:0 30px; cursor: pointer}

.wrap{ width:1200px; margin: 0 auto;}

.pop-bg{background:rgba(0,0,0,.8);position:fixed;width:100%;height:100%;left:0;top:0; z-index: 100;}
.pop{width:402px;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);background:#fff;color:#666;font-size:12px;padding-top:50px;text-align:center;border-radius:5px;overflow:hidden}
.pop .close{ position: absolute; right:10px; top:10px; width:24px; height: 24px; background: url(../../assets/images/close4.png) no-repeat;}
.pop .name{text-align:center;font-size:26px}
.pop .login{width:285px;margin:0 auto;padding-top:20px}
.pop .login .item{height:40px;overflow:hidden;margin-top:20px; border-radius:5px;}
.pop .login input{height:40px;line-height:40px; background:#f0ecec; font-size:12px;color:#999999;width:100%;padding-left:10px;box-sizing:border-box}
.pop .login .code-inp{width:170px;float:left;}
.pop .login span{display:inline-block;width:115px;height:40px;line-height:40px;background:#cccccc;text-align:center;float:right;border-radius:0 5px 5px 0; cursor:pointer; color: #333333;}
.pop .btn{ width:285px; height:40px;line-height:40px;text-align:center;color:#fff;background:#f0b645;display:inline-block;font-size:18px;margin-top:20px; color:#333333;border-radius:20px;margin-bottom:20px; cursor: pointer}
.pop .loginTips{ color: #999999; padding-bottom:60px;}
.pop .other-ways{background:#eee;padding:30px 0 80px}
.pop .other-ways .tit{background:url(../../assets/images/xian.png) no-repeat center;width:452px;height:20px;line-height:20px;color:#999;margin:0 auto}
.pop .other-ways .ways{padding-top:30px}
.pop .other-ways .ways li{display:inline-block;width:44px;height:44px;margin:0 30px}