:global(.layer){ position:fixed; left:50%; top:50%; transform: translate(-50%, -50%); background: #ffffff; box-shadow:0px 0px 14px 3px rgba(12,12,12,0.11); z-index: 50;;}
:global(.layer .title){ font-size: 20px; color: #333333; text-align: center; border-bottom:3px solid #F0B645; height: 62px; line-height: 62px;}
:global(.layer .close){ position:absolute; right:20px; top:25px; width: 17px; height: 17px; background: url(../../assets/images/close-layer.png) no-repeat; cursor: pointer;}
:global(.layer .content){ padding:10px 60px;}
:global(.layer .content input){ width: 340px; padding-left:10px; height: 34px; border: 1px solid #CFCFCF; font-size: 14px; color: #999999; float: left;}
:global(.layer .content .item){ height: 36px; line-height: 36px; font-size: 20px; color: #333333; padding-top:30px}
:global(.layer .content .item .name){ display: inline-block; width: 100px; text-align: right; float: left;}
:global(.layer .content .item .drop-down){ width:350px; float: left;; position: relative;}
:global(.layer .content .item .drop-down span){ display: inline-block;width: 340px; padding-left:10px; height: 34px; line-height: 34px; border: 1px solid #CFCFCF; font-size: 14px; color: #999999;}
:global(.layer .item .drop-down ul){ position:absolute; left:0; top:40px; list-style: none; background:#ADACB1; width: 100%; z-index: 1; max-height: 300px; overflow-y: auto; display: none;}
:global(.layer .item .drop-down ul li){ padding-left:20px; font-size: 16px; color: #ffffff;}
:global(.layer .item .drop-down ul li:hover){ padding-left:20px; font-size: 16px; background: #ffffff; color: #333333;}
:global(.layer .bottom){ text-align: center; padding:40px 0;}
:global(.layer .bottom .button){ display: inline-block; width: 150px; height: 44px; line-height: 44px; font-size: 20px; color: #333333; background: #F0B645; border-radius: 22px/50%;}
:global(.layer-tips){ max-width:200px; padding:10px 10px; text-align: center; line-height: 30px; background: rgba(0,0,0,0.75); color: #ffffff; border-radius: 5px; position: fixed; top:50%; left: 50%; transform: translate(-50%, -50%); z-index: 60;}