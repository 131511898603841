/* 左侧菜单 */
:global(.my-menu li){ height: 89px; line-height: 89px; font-size:18px; color: #4C4C4C; border-bottom:1px solid #EDEDED; padding-left:35px; border-left:5px solid #ffffff; box-sizing: border-box; cursor: pointer;}
:global(.my-menu .on){ color:#F29D4F; border-left:5px solid #F29D4F;}
:global(.my-menu i){ display: inline-block; width:23px; height:23px; background:url(../../assets/images/icon.png) no-repeat; vertical-align:top; margin:33px 20px 0 0;}
:global(.my-menu .icon1){ background-position:0 0;}
:global(.my-menu .icon2){ background-position:0 -23px;}
:global(.my-menu .icon3){ background-position:0 -46px;}
:global(.my-menu .icon4){ background-position:0 -69px;}
:global(.my-menu .icon5){ background-position:0 -92px;}
:global(.my-menu .icon6){ background-position:0 -115px;}
:global(.my-menu .on .icon1){ background-position:-23px 0;}
:global(.my-menu .on .icon2){ background-position:-23px -23px;}
:global(.my-menu .on .icon3){ background-position:-23px -46px;}
:global(.my-menu .on .icon4){ background-position:-23px -69px;}
:global(.my-menu .on .icon5){ background-position:-23px -92px;}
:global(.my-menu .on .icon6){ background-position:-23px -115px;}
.bottom-border{ position: absolute; left:0; bottom:0; width:200px; height: 1px; background: #EDEDED;}