.assess{height:65px; width:100%;background:rgba(0, 0, 0, .7);padding-top:25px; position: fixed; bottom: 0; z-index: 100;}
.assess .lu{float:left; height: 40px;}
.assess .input-box{float:left;padding:0px 0 0 24px}
.assess .input-box input{float:left;font-size:14px;color:#fff;width:138px;height:38px;border:1px solid #fff;line-height:38px;text-align:center}
.assess .input-box .long-inp{width:192px;margin:0 30px}
.assess .input-box .drop-down{float:left;margin-right:50px;position:relative}
.assess .input-box .drop-down span{display:block;font-size:14px;color:#fff;width:138px;height:38px;border:1px solid #fff;line-height:38px;text-align:center}
.drop-down span em{ display: inline-block;width:100px;height:38px;overflow: hidden; text-overflow:ellipsis; white-space: nowrap;}
.assess .input-box .drop-down span i{display:inline-block;width:16px;height:9px;background:url(../../assets/images/down.png) no-repeat;margin-left:5px;vertical-align: top; margin-top:15px;}
.assess .input-box .drop-down .list{background:#adacb1;position:absolute;left:-12px;bottom:50px;min-width:162px;border-radius:10px;padding:10px 0; max-height:300px; overflow-y:auto; font-size:16px;color:#fff; z-index: 5;}
.assess .input-box .drop-down .list li{padding:0 20px;height:34px;line-height:34px;white-space:nowrap;}
.assess .input-box .drop-down .list li:hover{color:#333;background:#fff}
.assess .button{width:138px;height:42px;border:1px solid #F0B645; background:#F0B645; border-radius:22px;float:left;text-align:center;line-height:42px;font-size:14px;color:#000000; cursor: pointer;}
.assess :global(.wrap){ position: relative;}
.assess .close{ width: 32px; height: 32px; background: url(../../assets/images/close2.png) no-repeat; position: absolute; top:3px; right: 0px; cursor: pointer;}

.footer{background:#292929}
.foot-title{color:#fff; overflow: hidden; height: 44px; line-height: 10px; vertical-align: top; cursor: pointer;}
.foot-title span{font-size:12px; line-height: 22px; vertical-align: top;}
.foot-title p{font-size:14px; line-height: 22px; vertical-align: top;}
.foot-title .tit{ font-size: 0px; padding:0 30px;height: 44px; display: inline-block;}
.foot-title .tit:hover{ color: #F0B645;}
.foot-title .tit:hover a{ color: #F0B645;}
.foot-title .on{ color: #F0B645;}
.foot-info{width:1200px;margin:0 auto;padding-top:40px}
.footer-logo{ width: 224px; height: 87px; float: left;margin-right: 75px;}
.footer-logo img{width: 100%; height: 100%;}
.foot-info .intr{ width: 620px; float: left; padding:15px 0 30px;}
.code-pos{ float: right; width: 280px; padding-top:20px;}
.qr-code{float:left;width:112px;font-size:14px; text-align:center; color:#fff;margin-left:5%}
.qr-code p{padding-top:10px; text-align: center;}
.qr-code img{width:110px; height: 110px;}

.partner{margin:0 0 4% -20px;overflow:hidden; padding-left:30px;}
.partner li{font-size:12px;color:#c6c6c6;float:left;text-align:center;height:25px;line-height:25px;margin:20px 0px 0 20px;overflow:hidden; float: left;}
.partner li a{color:#c6c6c6}
.company{margin:1.5% 0 5%;overflow:hidden}
.company-one{float:left;margin-right:2%}
.company-name{font-size:14px;color:#fff;line-height:35px;padding-bottom:5px; cursor: pointer;}
.company-name .line{ width:24px; height: 1px; background: #D8D8D8; margin:18px 25px 0; display: inline-block; vertical-align: top;}
.company-name .on{ color: #F0B645;}
.company-item{font-size:12px;color:#c6c6c6;line-height:25px;height:25px;overflow:hidden}
.company-info{ padding:30px 0 0 30px;}
.foot-copyright{clear:both;text-align:center;font-size:16px;color:#999;height:80px;display:flex;align-items:center;justify-content:center;border-top:1px solid #666; margin:0 auto; width: 1200px;}
.foot-copyright p span{margin-right:10px}
.foot-copyright p span img{margin-right:3px}
.mar-no-t{margin-top:0}

.assess input::-webkit-input-placeholder{
    color:#ffffff;
}
.assess input::-moz-placeholder{   /* Mozilla Firefox 19+ */
    color:#ffffff;;
}
.assess input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
    color:#ffffff;;
}
.assess input:-ms-input-placeholder{  /* Internet Explorer 10-11 */ 
    color:#ffffff;;
}