.bg{ background:#2E2E2E;}
.position{ font-size: 16px; color: #999999; padding:30px 0 22px;}
.position span{ color: #ffffff;}
.topmenu{ font-size: 12px; color: #999999;}
.topmenu span{ margin-right: 20px; display: inline-block; height: 24px; padding:0 20px; line-height: 24px; cursor: pointer;}
.topmenu .on{ background: #F0B645; color: #ffffff; border-radius: 12px;}

.list{ margin-left: -24px; padding-bottom: 50px; overflow: hidden;}
.list .item{ width: 180px; margin: 26px 0 0 24px;background: #ffffff; text-align: center; font-size: 14px; color: #666666; float: left;}
.list .item .pic{ width: 180px; height: 185px; cursor: pointer;}
.list .item .info{ padding:8px 0; height: 96px;}
.list .item p{ color: #333333; font-weight: bold; height: 22px; line-height: 22px; overflow: hidden;}
.list .item span{ display: block; height: 22px; line-height: 22px; overflow: hidden;}

.popbg{ background: rgba(0, 0, 0, 0.8); left: 0; top:0; bottom: 0; right: 0; z-index: 10; position:fixed;}