.bg333{ background: #333333;}
.bgf0{ background: #F0F0F0;}
.main{width:1200px; margin: 0 auto;}
.banner1{ background: url(../../assets/images/xy_02.jpg) no-repeat center top; height: 120px;}
.banner2{ background: url(../../assets/images/xy_03.jpg) no-repeat center top; height: 120px;}
.banner3{ background: url(../../assets/images/xy_04.jpg) no-repeat center top; height: 120px;}
.banner4{ background: url(../../assets/images/xy_05.jpg) no-repeat center top; height: 120px;}
.banner5{ background: url(../../assets/images/xy_06.jpg) no-repeat center top; height: 120px;}
.banner{vertical-align:top; height: 340px; background: url(../../assets/images/banner-offer.png) no-repeat center top;}
.banner img{ width: 100%;}
.cgl{ background: url(../../assets/images/xy_07.jpg) no-repeat center top; height: 135px;}
.school-bg{ background: url(../../assets/images/tea-bg.jpg) no-repeat center top;}
.student-bg{ background: url(../../assets/images/stu-bg.jpg) no-repeat center top;}

.offer{width:1200px; margin:0 auto; position: relative; overflow: hidden;}
.offer li img{ width: 100%; box-shadow: 0 0 50px 0px #e5e5e6}

.video-list{overflow:hidden; width: 1200px; margin:0 auto; position: relative; margin-bottom: 50px;}.video-list li{height:350px;float:left}
.video-list .video{height:350px;overflow:hidden;float:left}
.video-list .video img{width:100%; height: 100%;}
.video-list .info{width:203px;height:290px;padding:60px 30px 0;line-height:30px;float:left;background:#fff;font-size:16px;color:#666;text-align:left}
.video-list .info .name span{font-size:20px;color:#333;display:block}
.video-list .info .source{padding-top:30px}
.video-list .info .source span{color:#333}
.video-list .info .play-btn{background:url(../../assets/images/play-btn.png) no-repeat left center;padding-left:60px;height:40px;line-height:40px;color:#333;margin-top:50px}
.video-list .swiper-slide{height:350px;float:left}

.videos{ margin: -25px 0 0 -25px;}
.videos .nr{ width: 284px; height: 159px; margin: 25px 0 0 26px; float: left; position: relative;}
.videos .nr:first-of-type{width: 578px;height: 342px;}
.videos .nr:first-of-type .bz{height: 62px; line-height: 62px; font-size: 24px;}
.videos .nr:first-of-type .bz .playbtn{ width:68px; height: 68px; bottom: 28px; right: 55px;}
.videos .nr:first-of-type .bz span{ width: 400px;}
.videos .nr .pic{ width: 100%; height: 100%;}
.videos .nr img{ width: 100%; height: 100%; cursor: pointer;}
.videos .nr .bz{ height: 30px; line-height: 30px; font-size: 14px; color: #ffffff; text-indent: 1em; position: absolute; left:0; bottom: 0; background: rgba(0, 0, 0, .8); width: 100%;}
.videos .nr .bz span{ white-space:nowrap; text-overflow:ellipsis; display: block; width: 200px; overflow: hidden;}
.videos .nr .bz .playbtn{ width: 33px; height: 33px; background: url(../../assets/images/play-btn3.png) no-repeat; background-size: contain; position: absolute; bottom: 15px; right: 26px; cursor:pointer;}

.popbg{ background: rgba(0, 0, 0, 0.8); left: 0; top:0; bottom: 0; right: 0; z-index: 10; position:fixed;}
.videobox{ width:1123px; height: 570px; position:fixed; top:50%; left: 50%; transform: translate(-50%, -50%);}
.videobox video{ width: 100%; height: 100%;}
.video-close{ position: absolute; top:0; right: 0; width: 24px; height: 24px; background: url(../../assets/images/video-close.png) no-repeat; cursor: pointer; z-index: 5;}
